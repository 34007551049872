export interface option {
    id: number;
    name: string;
    value: boolean;

}

export class CheckBoxOption {

    checkBoxArray: option[] = [
        { id: 1, name: "YES", value: true },
        { id: 2, name: "NO", value: false },
    ]

}

