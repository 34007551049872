<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">

            <h2>{{'Picknick-Event für junge Frauen'| translate}}</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-70 pb-70">
    <div class="container">

        <div class=" application">
            <div class="tab-wrap application">

                <input type="radio" id="tab1" name="tabGroup1" class="tab" checked>

                <div class="tab__content personal ">
                    <h3>{{'EVENT_APPLICATION_FORM'|translate}}</h3>
                    <form class=" col md-12" [formGroup]="eventForm">

                        <div class="col-md-12">
                            <label for="firstName" class="form-label">{{ 'NAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="firstName"
                                placeholder="{{ 'NAME' | translate }}" required>
                            <div *ngIf="eventForm.get('firstName')?.invalid && (eventForm.get('firstName')?.dirty || eventForm.get('firstName')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label for="lastName" class="form-label">{{ 'SURNAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="lastName"
                                placeholder="{{ 'SURNAME' | translate }}" required>
                            <div *ngIf="eventForm.get('lastName')?.invalid && (eventForm.get('lastName')?.dirty || eventForm.get('lastName')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label for="email" class="form-label">{{ 'TELEPHONE' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="email" class="form-control" id="email" formControlName="email"
                                placeholder="{{ 'TELEPHONE' | translate }}">
                            <div *ngIf="eventForm.get('email')?.invalid && (eventForm.get('email').dirty || eventForm.get('email').touched)"
                                class="alert">
                                <div *ngIf="eventForm.get('email').errors?.required">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>
                                <!-- <div *ngIf="eventForm.get('email')?.errors?.email" class="alert">
                                    {{ 'PLEASE_ENTER_A_VALID_EMAIL' | translate }}
                                </div> -->
                            </div>
                        </div>
                        <button [disabled]="isLoading || !eventForm.valid " (click)="saveForm() " class="btn btn-primary">
                            <span *ngIf="!isLoading">{{'SUBMIT'|translate}}</span>
                            <span *ngIf="isLoading">
                                <i class="fa fa-spinner fa-spin"></i> {{'SENDING'|translate}}
                            </span>
                        </button>


                    </form>
                 
                </div>



            </div>

        </div>
   
    </div>
</div>