<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap" rel="stylesheet">

<link rel="shortcut icon"
    href="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA4OCAxMDIuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgODggMTAyLjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMDY4MkI0O30KCS5zdDF7ZmlsbDojMzFCN0U5O30KCS5zdDJ7ZmlsbDpub25lO30KPC9zdHlsZT4KPGc+Cgk8Zz4KCQk8Zz4KCQkJPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSI4OCw1Mi42IDg4LDUyLjYgMzguNCwzIDI0LjIsMTcuMiA1OS43LDUyLjYgMjQuMyw4OCAzOC40LDEwMi4yIAkJCSIvPgoJCTwvZz4KCQk8Zz4KCQkJPHBvbHlnb24gY2xhc3M9InN0MSIgcG9pbnRzPSI4NSw0OS42IDg1LDQ5LjYgMzUuNCwwIDIxLjIsMTQuMiA1Ni43LDQ5LjYgMjEuMyw4NSAzNS40LDk5LjIgCQkJIi8+CgkJPC9nPgoJPC9nPgoJPGxpbmUgY2xhc3M9InN0MiIgeDE9IjQwLjMiIHkxPSIxMDMuOCIgeDI9IjkwLjEiIHkyPSI1NC4yIi8+Cgk8Zz4KCQk8cG9seWxpbmUgY2xhc3M9InN0MCIgcG9pbnRzPSIxMC41LDc0LjQgMTQuMSw3OCA0Mi40LDQ5LjYgMzguNyw0NS44IAkJIi8+CgkJPHBvbHlsaW5lIGNsYXNzPSJzdDEiIHBvaW50cz0iMzguNyw0NS45IDE0LjIsMjEuMyAwLDM1LjQgMTQuMiw0OS42IDAsNjMuOCAxMC42LDc0LjQgCQkiLz4KCTwvZz4KPC9nPgo8L3N2Zz4K" />

<style>
    .container {
        max-width: 960px;
        margin: 0 auto;
    }

    .container.card {
        border: 0;
        margin-top: 5.5rem;
        /* background-color: #ffffff;
        border-radius: 10px;
        box-shadow: -5px -4px 47px 3px rgba(198, 198, 198, 0.3); */
        text-align: center;
        padding: 3.6rem;
        /* padding-bottom: 3.6rem; */
    }

    .title-main {
        font-size: 3.6rem;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 2.1rem;
    }

    .main-image svg {
        max-width: 61rem;
        margin-bottom: 2rem;
    }

    .subheading {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 3rem;
        font-family: 'Open Sans', sans-serif !important;
        color: #1d2a3b;
    }

    .card .text {
        font-size: 13px;
        letter-spacing: 0.26px;

    }

    footer {
        color: #b1b1b1;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1.7rem;
    }

    footer .logo svg {
        width: 145px;
        padding-left: 11px;
    }

    footer .left-side {
        display: flex;
        align-items: center;
    }

    /* Medias */
    @media screen and (max-width: 1024px) {
        .container {
            max-width: 90%;
        }

        @media screen and (max-width: 768px) {
            html {
                font-size: 7px;
            }

            .container {
                max-width: 90%;
            }

            .container.card {
                margin-top: 6rem;
            }

            .card .text {
                font-size: 1.6rem;
            }
        }

        @media screen and (max-width: 480px) {
            html {
                font-size: 5.7px;
            }

            .container {
                max-width: 95%;
            }

            .title-main {
                font-size: 6.6vw;
            }

            /* .container.card {margin-top: 2rem;} */
            .subheading {
                font-size: 13px;
            }

            .card .text {
                font-size: 12px;
            }

            footer {
                flex-direction: column;
            }
        }
    }
</style>



<body>
    <section id="default-card" class="default-card">
        <div class="container card">
            <!-- <h1 class="title-main">wolinka.com.tr</h1> -->
            <p class="subheading">{{'THIS_PAGE_IS_CURRENTLY_UNDER_MAINTENANCE'|translate}}</p>
            <div class="main-image">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                    y="0px" viewBox=" 0 90 500 260" xml:space="preserve">
                    <style type="text/css">
                        .st0 {
                            fill: #FFFFFF;
                        }

                        .st1 {
                            fill: #6CDD6C;
                        }

                        .st2 {
                            fill: #50AA50;
                        }

                        .st3 {
                            fill: #106797;
                        }

                        .st4 {
                            fill-rule: evenodd;
                            clip-rule: evenodd;
                            fill: #A3D5E9;
                        }

                        .st5 {
                            fill-rule: evenodd;
                            clip-rule: evenodd;
                            fill: #106797;
                        }

                        .st6 {
                            fill: #A3D5E9;
                        }

                        .st7 {
                            fill: #405B91;
                        }

                        .st8 {
                            fill: #B6D0FB;
                        }

                        .st9 {
                            fill: #8AB436;
                        }

                        .st10 {
                            fill: #C1E9F4;
                        }

                        .st11 {
                            fill: #415B91;
                        }
                    </style>
                    <g id="BACKGROUND">
                        <rect class="st0" width="500" height="500" />
                    </g>
                    <g id="OBJECTS">
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path class="st1" d="M317.7,334.6c-0.5-1.6-1.8-3.1-3.5-3.6s-4,0.3-4.5,1.9c-1.6-1.2-4.1-0.3-5.4,1.2c-2.1,2.4-0.5,6.4-0.1,6.6
                                                        c0.4,0.3,1.4,0.2,1.4,0.2l15.7-0.1c0,0,2.9-0.2,4.2-0.7c2.7-1,4.5-5.1,2.8-7.2c-1.2-1.4-3.5-1.7-5.4-1.2
                                                        C320.9,332.4,319.1,333.4,317.7,334.6" />
                                    </g>
                                    <g>
                                        <path class="st2"
                                            d="M305.3,341.5c-0.4,0-1.1,0-1.5-0.3c-0.6-0.5-2.2-4.7,0-7.3c0.9-1.1,2.3-1.8,3.7-2c0.7-0.1,1.3,0,1.9,0.3
                                                        c0.3-0.5,0.9-1,1.5-1.3c1-0.5,2.3-0.6,3.4-0.3c1.6,0.5,2.9,1.6,3.6,3.2c1.5-1.1,3.2-2,4.8-2.4c1.9-0.5,4.5-0.3,5.9,1.4
                                                        c0.8,1,1,2.4,0.5,4c-0.6,1.8-2,3.4-3.5,4c-1.4,0.5-4.2,0.7-4.4,0.7l-15.7,0.1C305.4,341.5,305.4,341.5,305.3,341.5z
                                                         M307.9,332.8c-0.1,0-0.2,0-0.3,0c-1,0.1-2.2,0.7-3,1.6c-1.8,2.1-0.5,5.7-0.2,6c0.1,0,0.6,0.1,1,0.1l15.7-0.1
                                                        c0,0,2.8-0.2,4-0.6c1.2-0.4,2.4-1.8,2.9-3.3c0.4-1.3,0.3-2.4-0.3-3.2c-1.1-1.3-3.3-1.5-4.8-1c-1.6,0.5-3.4,1.4-5,2.7
                                                        c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.3-0.2-0.4-0.3c-0.6-1.6-1.7-2.9-3.2-3.3c-0.8-0.2-1.8-0.2-2.6,0.2
                                                        c-0.7,0.3-1.1,0.8-1.3,1.3c-0.1,0.1-0.2,0.3-0.3,0.3c-0.2,0-0.3,0-0.5-0.1C308.9,332.9,308.4,332.8,307.9,332.8z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st2" d="M301.1,334.1c-0.4,0.8-0.6,1.6-0.5,2.5c0.1,0.4,0.8,0.4,0.8,0c-0.1-0.8,0-1.5,0.4-2.2
                                                            C302,334,301.4,333.7,301.1,334.1L301.1,334.1z" />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st2" d="M298.9,334.6c-0.2,0.8-0.3,1.6-0.1,2.4c0.1,0.4,0.9,0.3,0.8-0.2c-0.2-0.7-0.1-1.3,0.1-2
                                                            C299.8,334.4,299,334.2,298.9,334.6L298.9,334.6z" />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st2"
                                                d="M303.2,333.2c0.5,0,0.5-0.7,0-0.7C302.7,332.5,302.7,333.2,303.2,333.2L303.2,333.2z" />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st2" d="M305.4,336.6c0,0.2,0,0.4,0,0.6c0,0.5,0.8,0.5,0.8,0c0-0.2,0-0.4,0-0.6
                                                            C306.2,336.2,305.4,336.2,305.4,336.6L305.4,336.6z" />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st2" d="M318.6,337.7c0,0.1,0,0.2,0,0.3c0,0.5,0.8,0.5,0.8,0c0-0.1,0-0.2,0-0.3
                                                            C319.4,337.2,318.6,337.2,318.6,337.7L318.6,337.7z" />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st2" d="M317.1,336.9c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.3,0.9-0.2,0.5-0.5c-0.1-0.1-0.1-0.1-0.2-0.2
                                                            C317.3,336,316.8,336.5,317.1,336.9L317.1,336.9z" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <path class="st3" d="M96.1,319.2H69.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h26.3c0.6,0,1,0.5,1,1C97.2,318.8,96.7,319.2,96.1,319.2z
                                                " />
                            </g>
                            <g>
                                <g>
                                    <path class="st0" d="M328,227.9h-0.8c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1h-0.8c0-1,0.8-1.9,1.9-1.9
                                                    C327.1,226,328,226.9,328,227.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path class="st0" d="M330.6,184.1h-0.8c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1h-0.8c0-1,0.8-1.9,1.9-1.9
                                                    C329.8,182.2,330.6,183.1,330.6,184.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path class="st3"
                                            d="M237,336.3h-35.8l0.6-1.4c2.3-5.6,2.6-10.3,2.2-15.8L204,318l30.2-0.1l0,1c0,3.5,1,11.5,2.4,16L237,336.3z
                                                         M204.3,334.2h29.9c-1.2-4.4-1.9-10.6-2.1-14.3l-25.9,0.1C206.5,324.9,206.2,329.2,204.3,334.2z" />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path class="st3" d="M191,319.9c-27.2,0-49.2-0.1-50.1-0.5c-2.3-0.8-5.4-4.1-5.4-6.3c-0.1-5-0.3-11.7-0.5-19.5
                                                        c-0.7-23.5-1.8-55.8-0.4-73.6c0.2-3.3,2.9-5.8,6.1-5.8c0,0,0.1,0,0.1,0l156.6,0.2c3.3,0.1,6,2.7,6,6.1
                                                        c0.1,5.2,0.3,10.5,0.5,15.9c0.7,22.6,1.5,46-1,68.1c-0.1,0.9-0.1,1.8-0.2,2.8c-0.2,4.6-0.5,10.4-6.4,11.6
                                                        C293.2,319.5,235.2,319.9,191,319.9z M140.6,215.9c-2.4,0-4.3,1.8-4.5,4.2c-1.3,17.8-0.3,49.9,0.4,73.4
                                                        c0.2,7.8,0.5,14.6,0.5,19.5c0,1.4,2.4,4.1,4.3,4.8c3.3,0.7,150.6,0.2,154.4-0.5c4.7-0.9,4.9-5.6,5.1-10c0-1,0.1-2,0.2-2.9
                                                        c2.5-22,1.7-45.3,1-67.8c-0.2-5.4-0.3-10.7-0.5-15.9c-0.1-2.5-2-4.4-4.4-4.5L140.6,215.9C140.6,215.9,140.6,215.9,140.6,215.9z
                                                        " />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path class="st4"
                                        d="M292.9,219.8l-147.6-0.2c-2.7,0-4.9,1.8-5.1,4.2c-1.7,19.8,0.6,61,0.9,77.6c0,1.6,2.7,4,4.6,4.6
                                                    c2.2,0.7,142.5,0.2,145.9-0.4c6.2-1.1,5.1-7.4,5.6-11.4c2.9-22.8,1.1-47.6,0.5-69.9C297.8,221.8,295.6,219.9,292.9,219.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path class="st3" d="M249.8,342h-61.4c-2.4,0-3.7-1.9-3.7-3.8c0-1.9,1.3-3.8,3.7-3.8h61.4c2.4,0,3.7,1.9,3.7,3.8
                                                        C253.6,340,252.3,342,249.8,342z M188.4,336c-1.6,0-2,1.3-2,2.1c0,0.8,0.4,2.1,2,2.1h61.4c1.6,0,2-1.3,2-2.1
                                                        c0-0.8-0.4-2.1-2-2.1H188.4z" />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st5" d="M145.7,312C145.3,312,145.3,312,145.7,312" />
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path class="st3"
                                                        d="M145.7,314.1c2.8,0,2.8-4.3,0-4.3C142.9,309.8,142.9,314.1,145.7,314.1L145.7,314.1z" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st5" d="M154.3,312C154,312,154,312,154.3,312" />
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path class="st3"
                                                        d="M154.3,314.1c2.8,0,2.8-4.3,0-4.3C151.6,309.8,151.6,314.1,154.3,314.1L154.3,314.1z" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st5" d="M163,312C162.6,312,162.6,312,163,312" />
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path class="st3"
                                                        d="M163,314.1c2.8,0,2.8-4.3,0-4.3C160.2,309.8,160.2,314.1,163,314.1L163,314.1z" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st5" d="M219.1,217.6C219,217.6,219,217.6,219.1,217.6" />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st3"
                                                    d="M219.1,218.6c1.3,0,1.3-2,0-2C217.8,216.6,217.8,218.6,219.1,218.6L219.1,218.6z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <polygon class="st6"
                                        points="392.3,337.9 371.2,337.9 379.3,311.2 384.2,311.2 				" />
                                </g>
                                <g>
                                    <rect x="369.4" y="337.9" class="st6" width="24.7" height="3.1" />
                                </g>
                                <g>
                                    <polygon class="st3"
                                        points="373.2,331.2 371.2,337.9 392.3,337.9 390.3,331.2 				" />
                                </g>
                                <g>
                                    <polygon class="st3"
                                        points="375.2,324.5 388.2,324.5 386.2,317.8 377.3,317.8 				" />
                                </g>
                            </g>
                            <g id="XMLID_11_">
                                <g id="XMLID_493_">
                                    <path class="st6"
                                        d="M401,340.8h4.7v-18.2h24.1v18.2h4.7v-18.2h5v-14.5h-4v-1.3h-6.8v1.3h-21.4v-1.3h-6.8v1.3h-4.4v14.5h4.9
                                                    V340.8z M437.9,309.6L437.9,309.6l-2.9,11.5h-5.2l2.9-11.5H437.9z M424.4,321.1h-5.2l2.9-11.5h5.2L424.4,321.1z M413.9,321.1
                                                    h-5.2l2.9-11.5h5.2L413.9,321.1z M401,309.6h5.2l-2.9,11.5h-5.2L401,309.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path class="st6" d="M170,163.1l1.4-1.4c0.3-0.3,0.3-0.7,0-1l-1.2-1.2c-0.3-0.3-0.4-0.8-0.2-1.2l1-2.4c0.1-0.4,0.5-0.7,0.9-0.7
                                                        h1.7c0.4,0,0.7-0.3,0.7-0.7v-2c0-0.4-0.3-0.7-0.7-0.7H172c-0.4,0-0.8-0.3-0.9-0.7l-1-2.4c-0.2-0.4-0.1-0.9,0.2-1.2l1.2-1.2
                                                        c0.3-0.3,0.3-0.7,0-1l-1.4-1.4c-0.3-0.3-0.7-0.3-1,0l-1.2,1.2c-0.3,0.3-0.8,0.4-1.2,0.2l-2.4-1c-0.4-0.1-0.7-0.5-0.7-0.9v-1.7
                                                        c0-0.4-0.3-0.7-0.7-0.7h-2c-0.4,0-0.7,0.3-0.7,0.7v1.7c0,0.4-0.3,0.8-0.7,0.9l-2.4,1c-0.4,0.2-0.9,0.1-1.2-0.2l-1.2-1.2
                                                        c-0.3-0.3-0.7-0.3-1,0l-1.4,1.4c-0.3,0.3-0.3,0.7,0,1l1.2,1.2c0.3,0.3,0.4,0.8,0.2,1.2l-1,2.4c-0.1,0.4-0.5,0.7-0.9,0.7h-1.7
                                                        c-0.4,0-0.7,0.3-0.7,0.7v2c0,0.4,0.3,0.7,0.7,0.7h1.7c0.4,0,0.8,0.3,0.9,0.7l1,2.4c0.2,0.4,0.1,0.9-0.2,1.2l-1.2,1.2
                                                        c-0.3,0.3-0.3,0.7,0,1l1.4,1.4c0.3,0.3,0.7,0.3,1,0l1.2-1.2c0.3-0.3,0.8-0.4,1.2-0.2l2.4,1c0.4,0.1,0.7,0.5,0.7,0.9v1.7
                                                        c0,0.4,0.3,0.7,0.7,0.7h2c0.4,0,0.7-0.3,0.7-0.7v-1.7c0-0.4,0.3-0.8,0.7-0.9l2.4-1c0.4-0.2,0.9-0.1,1.2,0.2l1.2,1.2
                                                        C169.3,163.3,169.8,163.3,170,163.1z M161.8,159.2c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8
                                                        C167.6,156.6,165,159.2,161.8,159.2z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st6" d="M183.5,168.6l-0.4-1.2c-0.1-0.2-0.3-0.4-0.6-0.3l-1,0.3c-0.2,0.1-0.5,0-0.7-0.3l-1-1.3
                                                        c-0.2-0.2-0.2-0.5-0.1-0.7l0.5-0.9c0.1-0.2,0-0.5-0.2-0.6L179,163c-0.2-0.1-0.5,0-0.6,0.2l-0.5,0.9c-0.1,0.2-0.4,0.4-0.7,0.3
                                                        l-1.6-0.2c-0.2,0-0.5-0.2-0.6-0.4l-0.3-1c-0.1-0.2-0.3-0.4-0.6-0.3l-1.2,0.4c-0.2,0.1-0.4,0.3-0.3,0.6l0.3,1
                                                        c0.1,0.2,0,0.5-0.3,0.7l-1.3,1c-0.2,0.2-0.5,0.2-0.7,0.1l-0.9-0.5c-0.2-0.1-0.5,0-0.6,0.2l-0.6,1.1c-0.1,0.2,0,0.5,0.2,0.6
                                                        l0.9,0.5c0.2,0.1,0.4,0.4,0.3,0.7l-0.2,1.6c0,0.2-0.2,0.5-0.4,0.6l-1,0.3c-0.2,0.1-0.4,0.3-0.3,0.6l0.4,1.2
                                                        c0.1,0.2,0.3,0.4,0.6,0.3l1-0.3c0.2-0.1,0.5,0,0.7,0.2l1,1.3c0.2,0.2,0.2,0.5,0.1,0.7l-0.5,0.9c-0.1,0.2,0,0.5,0.2,0.6l1.1,0.6
                                                        c0.2,0.1,0.5,0,0.6-0.2l0.5-0.9c0.1-0.2,0.4-0.4,0.7-0.3l1.6,0.2c0.3,0,0.5,0.2,0.6,0.4l0.3,1c0.1,0.2,0.3,0.4,0.6,0.3l1.2-0.4
                                                        c0.2-0.1,0.4-0.3,0.3-0.6l-0.3-1c-0.1-0.2,0-0.5,0.2-0.7l1.3-1c0.2-0.2,0.5-0.2,0.7-0.1l0.9,0.5c0.2,0.1,0.5,0,0.6-0.2l0.6-1.1
                                                        c0.1-0.2,0-0.5-0.2-0.6l-0.9-0.5c-0.2-0.1-0.4-0.4-0.3-0.7l0.2-1.6c0-0.2,0.2-0.5,0.4-0.6l1-0.3
                                                        C183.4,169,183.6,168.8,183.5,168.6z M179,171.9c-0.9,1.8-3.1,2.4-4.9,1.5c-1.8-0.9-2.4-3.1-1.5-4.9c0.9-1.8,3.1-2.4,4.9-1.5
                                                        C179.3,168,179.9,170.2,179,171.9z" />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <path class="st3" d="M242.4,191.3h-32.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h32.6c0.6,0,1,0.5,1,1
                                                C243.4,190.9,243,191.3,242.4,191.3z" />
                            </g>
                            <g>
                                <path class="st3" d="M176,206.1h-52.7c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1H176c0.6,0,1,0.5,1,1C177.1,205.7,176.6,206.1,176,206.1z
                                                " />
                            </g>
                            <g>
                                <path class="st3" d="M428.5,234.2h-52.7c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h52.7c0.6,0,1,0.5,1,1
                                                C429.5,233.7,429.1,234.2,428.5,234.2z" />
                            </g>
                            <g>
                                <path class="st3"
                                    d="M449.2,343H50.8c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h398.4c0.6,0,1,0.5,1,1C450.2,342.5,449.8,343,449.2,343z" />
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path class="st6"
                                            d="M303.1,178.3c-2.2,0-4.3,0.9-5.9,2.4c-2.7,2.7-3.2,6.9-1.3,10.1l0.1,0.2l-8.5,8.5c0,0-0.4,0.4-0.4,1
                                                        c0,0.4,0.2,0.9,0.6,1.3c0.5,0.5,0.9,0.7,1.4,0.7c0.6,0,1-0.3,1-0.3l8.4-8.5l0.2,0.1c1.3,0.8,2.7,1.1,4.2,1.1
                                                        c2.2,0,4.3-0.9,5.9-2.4c3.3-3.3,3.3-8.6,0-11.8C307.4,179.2,305.3,178.3,303.1,178.3z M303.1,192.5c-3.2,0-5.9-2.6-5.9-5.9
                                                        c0-3.2,2.6-5.9,5.9-5.9l0,0c3.2,0,5.9,2.6,5.9,5.9C308.9,189.9,306.3,192.5,303.1,192.5z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st6" d="M300,187.9h6.1v-1.6H300V187.9z" />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path class="st3" d="M147.7,341.1c-0.6,0-1-0.5-1-1v-79.9c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1V340
                                                    C148.8,340.6,148.3,341.1,147.7,341.1z" />
                                </g>
                                <g>
                                    <path class="st3" d="M166.3,341.1c-0.6,0-1-0.5-1-1v-79.9c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1V340
                                                    C167.3,340.6,166.9,341.1,166.3,341.1z" />
                                </g>
                                <g>
                                    <path class="st3"
                                        d="M166.3,266.7h-18.6c-0.6,0-1-0.5-1-1s0.5-1,1-1h18.6c0.6,0,1,0.5,1,1S166.9,266.7,166.3,266.7z" />
                                </g>
                                <g>
                                    <path class="st3" d="M166.3,275.8h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                                    C167.3,275.3,166.9,275.8,166.3,275.8z" />
                                </g>
                                <g>
                                    <path class="st3" d="M166.3,284.9h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                                    C167.3,284.4,166.9,284.9,166.3,284.9z" />
                                </g>
                                <g>
                                    <path class="st3" d="M166.3,294h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1C167.3,293.6,166.9,294,166.3,294z
                                                    " />
                                </g>
                                <g>
                                    <path class="st3" d="M166.3,303.2h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                                    C167.3,302.7,166.9,303.2,166.3,303.2z" />
                                </g>
                                <g>
                                    <path class="st3" d="M166.3,312.3h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                                    C167.3,311.8,166.9,312.3,166.3,312.3z" />
                                </g>
                                <g>
                                    <path class="st3" d="M166.3,321.4h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                                    C167.3,320.9,166.9,321.4,166.3,321.4z" />
                                </g>
                                <g>
                                    <path class="st3" d="M166.3,330.5h-18.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h18.6c0.6,0,1,0.5,1,1
                                                    C167.3,330.1,166.9,330.5,166.3,330.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path class="st3"
                                        d="M122.5,264c-0.6,0-1-0.5-1-1v-4c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1v4C123.5,263.6,123,264,122.5,264z" />
                                </g>
                                <g>
                                    <path class="st3"
                                        d="M124.5,262h-4c-0.6,0-1-0.5-1-1s0.5-1,1-1h4c0.6,0,1,0.5,1,1S125.1,262,124.5,262z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path class="st3" d="M215.7,119.6c-0.6,0-1-0.5-1-1v-4c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1v4C216.8,119.1,216.3,119.6,215.7,119.6z
                                                    " />
                                </g>
                                <g>
                                    <path class="st3"
                                        d="M217.8,117.6h-4c-0.6,0-1-0.5-1-1s0.5-1,1-1h4c0.6,0,1,0.5,1,1S218.3,117.6,217.8,117.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path class="st3" d="M248.4,328.7c-0.1,0-0.2,0-0.3,0l-3.9-1.1c-0.6-0.2-0.9-0.7-0.7-1.3c0.2-0.6,0.7-0.9,1.3-0.7l3.9,1.1
                                                    c0.6,0.2,0.9,0.7,0.7,1.3C249.3,328.4,248.9,328.7,248.4,328.7z" />
                                </g>
                                <g>
                                    <path class="st3"
                                        d="M245.9,330c-0.1,0-0.2,0-0.3,0c-0.6-0.2-0.9-0.7-0.7-1.3l1.1-3.9c0.2-0.6,0.7-0.9,1.3-0.7
                                                    c0.6,0.2,0.9,0.7,0.7,1.3l-1.1,3.9C246.8,329.8,246.4,330,245.9,330z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path class="st3" d="M228,206.7c-0.1,0-0.2,0-0.3,0l-3.9-1.1c-0.6-0.2-0.9-0.7-0.7-1.3c0.2-0.6,0.7-0.9,1.3-0.7l3.9,1.1
                                                    c0.6,0.2,0.9,0.7,0.7,1.3C228.9,206.4,228.5,206.7,228,206.7z" />
                                </g>
                                <g>
                                    <path class="st3"
                                        d="M225.5,208.1c-0.1,0-0.2,0-0.3,0c-0.6-0.2-0.9-0.7-0.7-1.3l1.1-3.9c0.2-0.6,0.7-0.9,1.3-0.7
                                                    c0.6,0.2,0.9,0.7,0.7,1.3l-1.1,3.9C226.4,207.8,226,208.1,225.5,208.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <circle class="st6" cx="225.3" cy="168.5" r="14.4" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <circle class="st0" cx="225.3" cy="168.5" r="10.8" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st3"
                                                    d="M225.3,179.7c-6.2,0-11.2-5-11.2-11.2s5-11.2,11.2-11.2s11.2,5,11.2,11.2S231.5,179.7,225.3,179.7z
                                                                 M225.3,158.1c-5.7,0-10.4,4.7-10.4,10.4s4.7,10.4,10.4,10.4c5.7,0,10.4-4.7,10.4-10.4S231,158.1,225.3,158.1z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st3" d="M225.3,168.9c-0.2,0-0.4-0.2-0.4-0.4V163c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v5.5
                                                            C225.7,168.7,225.5,168.9,225.3,168.9z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path class="st3" d="M231.7,168.9h-6.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h6.4c0.2,0,0.4,0.2,0.4,0.4
                                                            C232.2,168.7,232,168.9,231.7,168.9z" />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <circle class="st7" cx="225.3" cy="158.9" r="0.4" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -46.3674 211.4673)"
                                                class="st7" cx="232.1" cy="161.7" rx="0.4" ry="0.4" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <circle class="st7" cx="234.9" cy="168.5" r="0.4" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <ellipse transform="matrix(0.7072 -0.707 0.707 0.7072 -55.9446 215.4091)"
                                                class="st7" cx="232.1" cy="175.2" rx="0.4" ry="0.4" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <circle class="st7" cx="225.3" cy="178" r="0.4" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <ellipse transform="matrix(0.707 -0.7072 0.7072 0.707 -59.9044 205.8822)"
                                                class="st7" cx="218.5" cy="175.2" rx="0.4" ry="0.4" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <circle class="st7" cx="215.7" cy="168.5" r="0.4" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -50.333 201.8934)"
                                                class="st7" cx="218.5" cy="161.7" rx="0.4" ry="0.4" />
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st7" d="M246.4,163h-6c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h6c0.2,0,0.4,0.2,0.4,0.4
                                                            C246.8,162.8,246.6,163,246.4,163z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path class="st7" d="M246.4,174.8h-6c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h6c0.2,0,0.4,0.2,0.4,0.4
                                                            C246.8,174.6,246.6,174.8,246.4,174.8z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path class="st7" d="M253.9,168.9h-11.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h11.3c0.2,0,0.4,0.2,0.4,0.4
                                                            C254.3,168.7,254.1,168.9,253.9,168.9z" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <rect x="266.9" y="324.5" class="st3" width="6.7" height="6.7" />
                                    <path class="st3"
                                        d="M274.1,331.7h-7.7V324h7.7V331.7z M267.5,330.7h5.6V325h-5.6V330.7z" />
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st3"
                                                d="M282.1,326h-6c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h6c0.2,0,0.4,0.2,0.4,0.4S282.3,326,282.1,326z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path class="st3" d="M284.4,330.6h-8.2c-0.3,0-0.6-0.2-0.6-0.4c0-0.2,0.3-0.4,0.6-0.4h8.2c0.3,0,0.6,0.2,0.6,0.4
                                                            C284.9,330.4,284.7,330.6,284.4,330.6z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path class="st3" d="M287.3,328.1H276c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h11.3c0.2,0,0.4,0.2,0.4,0.4
                                                            C287.7,328,287.6,328.1,287.3,328.1z" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <rect x="120.2" y="226.5"
                                        transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 247.1112 459.6004)"
                                        class="st0" width="6.7" height="6.7" />
                                    <path class="st3"
                                        d="M127.4,233.7h-7.7v-7.7h7.7V233.7z M120.7,232.6h5.6V227h-5.6V232.6z" />
                                </g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st3" d="M111.8,227.9h6c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-6c-0.2,0-0.4,0.2-0.4,0.4
                                                            C111.3,227.7,111.5,227.9,111.8,227.9z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path class="st3" d="M109.5,232.5h8.2c0.3,0,0.6-0.2,0.6-0.4s-0.3-0.4-0.6-0.4h-8.2c-0.3,0-0.6,0.2-0.6,0.4
                                                            S109.2,232.5,109.5,232.5z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path class="st3" d="M106.5,230.1h11.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-11.3c-0.2,0-0.4,0.2-0.4,0.4
                                                            C106.1,229.9,106.3,230.1,106.5,230.1z" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <rect x="324" y="251.6" class="st3" width="2.1" height="89.2" />
                                </g>
                                <g>
                                    <path class="st6" d="M325.3,262.4h-0.5c-3.4,0-6.2-2.8-6.2-6.2V248c0-3.4,2.8-6.2,6.2-6.2h0.5c3.4,0,6.2,2.8,6.2,6.2v8.2
                                                    C331.4,259.6,328.7,262.4,325.3,262.4z" />
                                </g>
                                <g>
                                    <g>
                                        <path class="st3" d="M333.4,256.4c0.7,0.1,1.5,0.2,2.2,0.4c0.5,0.1,0.7-0.7,0.2-0.7c-0.7-0.1-1.5-0.2-2.2-0.4
                                                        C333.1,255.6,332.9,256.3,333.4,256.4L333.4,256.4z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st3" d="M333.3,251.6c0.6-0.1,1.1-0.1,1.7-0.1c0.5,0,0.5-0.7,0-0.8c-0.6-0.1-1.3,0-1.9,0.1
                                                        C332.6,251,332.8,251.7,333.3,251.6L333.3,251.6z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st3" d="M333.1,247.3c0.6-0.2,1.1-0.4,1.7-0.7c0.5-0.2,0.3-0.9-0.2-0.8c-0.6,0.2-1.1,0.4-1.7,0.7
                                                        C332.4,246.7,332.6,247.5,333.1,247.3L333.1,247.3z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st3" d="M314,257.1c0.6-0.1,1.1-0.2,1.7-0.4c0.5-0.2,0.3-0.9-0.2-0.8c-0.5,0.2-1.1,0.3-1.7,0.4
                                                        C313.3,256.4,313.5,257.2,314,257.1L314,257.1z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st3" d="M313,251.6c0.7,0,1.4,0.1,2.1,0.3c0.5,0.1,0.7-0.6,0.2-0.8c-0.8-0.2-1.5-0.3-2.3-0.4
                                                        C312.5,250.8,312.5,251.6,313,251.6L313,251.6z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st3" d="M313.7,245.2c0.6,0.2,1.1,0.4,1.7,0.5c0.5,0.2,0.7-0.6,0.2-0.8c-0.6-0.2-1.1-0.4-1.7-0.5
                                                        C313.4,244.3,313.2,245,313.7,245.2L313.7,245.2z" />
                                    </g>
                                </g>
                                <g>
                                    <polygon class="st3" points="320.2,342 321.6,330 328.3,330 329.9,341.8 				" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path class="st1"
                                                d="M129.9,284.9c-0.3-1.9-1.6-6.8-4.6-5.2c-4.8,2.5,1,12-4.8,13.7c-0.3-3.8-1.1-6.4-2.8-6.2
                                                            c-3.5,0.3-2.6,8.5-2.7,10.4c-0.1,1.8-0.1,3.7-0.2,5.6c-4.2,0.7-3.3-10.1-5.9-11.6c-3.9-2.3-4.8,2.9-4.7,5.3
                                                            c0.2,4.1,1.3,9,5.1,11.1c1.3,0.7,3.5,1.5,5.2,1.4c0,3.3,0.1,6.5,0.7,9.6c0,0,0.1,0,0.1,0c0,0,0,0,0,0l2.3,0.1c0,0,0,0,0,0
                                                            c0,1,1.6,1,1.6,0c0-2,1.2-11.6,1.4-19.8c1.9,0.3,4.2-0.6,5.5-1.6C130.1,294.8,130.6,289.4,129.9,284.9z" />
                                        </g>
                                        <g>
                                            <path class="st2" d="M118.4,320.3c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.4-0.4-0.5-0.7l-1.7-0.1l-0.6,0l-0.1-0.4
                                                            c-0.5-2.6-0.7-5.6-0.7-9.2c-1.9,0-4-0.9-5-1.5c-4.1-2.3-5.2-7.5-5.3-11.5c-0.1-1.6,0.3-4.9,2.1-6c0.9-0.5,2-0.4,3.3,0.3
                                                            c1.2,0.7,1.7,2.9,2.2,5.5c0.7,3.5,1.4,6,3,6l0-0.9c0.1-1.5,0.1-2.9,0.2-4.4c0-0.3,0-0.7,0-1.2c-0.1-3.2-0.3-9.3,3.1-9.6
                                                            c0.5-0.1,1,0.1,1.4,0.5c0.9,0.8,1.6,2.6,1.8,5.6c2.1-1,2-3.4,1.9-6.3c-0.1-2.9-0.3-5.9,2.3-7.2c0.8-0.4,1.6-0.5,2.3-0.1
                                                            c2.1,1.1,2.8,5.2,2.9,5.7l0,0c0.6,3.5,0.8,9.8-3.9,13.2c-1.3,1-3.5,1.8-5.4,1.7c-0.1,5.4-0.7,11.2-1,15.2
                                                            c-0.2,2-0.3,3.5-0.3,4.2c0,0.5-0.2,0.8-0.6,1C118.9,320.2,118.7,320.3,118.4,320.3z M115.6,318.5l2.5,0.1l0,0.4
                                                            c0,0.2,0.1,0.3,0.1,0.3c0.1,0.1,0.3,0.1,0.4,0c0.1-0.1,0.2-0.1,0.2-0.3c0-0.7,0.1-2.2,0.3-4.2c0.4-4,0.9-10.2,1-15.6l0-0.5
                                                            l0.5,0.1c1.7,0.3,4-0.6,5.2-1.5c4.3-3.2,4.1-9.1,3.5-12.3l0,0c-0.2-1.4-1-4.3-2.4-5c-0.5-0.2-1-0.2-1.5,0.1
                                                            c-2,1.1-1.9,3.6-1.8,6.4c0.1,3,0.3,6.4-3.1,7.4l-0.5,0.2l0-0.5c-0.3-3.8-1-5.1-1.6-5.6c-0.2-0.2-0.5-0.3-0.8-0.3
                                                            c-2.6,0.2-2.4,5.9-2.3,8.7c0,0.6,0,1,0,1.3c0,1.4-0.1,2.9-0.2,4.4l-0.1,1.6l-0.3,0.1c-2.8,0.5-3.6-3.3-4.4-6.7
                                                            c-0.5-2.2-0.9-4.4-1.8-4.9c-1-0.6-1.8-0.7-2.4-0.3c-1.3,0.8-1.7,3.6-1.6,5.2c0.2,3.7,1.2,8.7,4.9,10.8c1.3,0.7,3.4,1.4,5,1.3
                                                            l0.5,0l0,0.5C115,313,115.2,315.9,115.6,318.5z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st3"
                                                    d="M125.3,341h-16.9c-0.9,0-1.6-0.6-1.8-1.5l-3.8-19.7l1.6-0.3l3.8,19.7c0,0.2,0.2,0.2,0.2,0.2h16.9
                                                                c0,0,0.2,0,0.2-0.2l3.8-19.7l1.6,0.3l-3.8,19.7C126.9,340.4,126.2,341,125.3,341z" />
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st6" d="M103,313.5h28c0.5,0,0.9,0.5,0.9,1.2v4.3c0,0.6-0.4,1.2-0.9,1.2h-28c-0.5,0-0.9-0.5-0.9-1.2v-4.3
                                                                C102.1,314,102.5,313.5,103,313.5z" />
                                            </g>
                                            <g>
                                                <path class="st7" d="M131,320.5h-28c-0.7,0-1.3-0.7-1.3-1.6v-4.3c0-0.9,0.6-1.6,1.3-1.6h28c0.7,0,1.3,0.7,1.3,1.6v4.3
                                                                C132.3,319.8,131.7,320.5,131,320.5z M103,313.9c-0.2,0-0.4,0.3-0.4,0.7v4.3c0,0.4,0.2,0.7,0.4,0.7h28c0.2,0,0.4-0.3,0.4-0.7
                                                                v-4.3c0-0.4-0.2-0.7-0.4-0.7H103z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path class="st8" d="M131,320.5h-28c-0.7,0-1.2-0.7-1.2-1.5v-4.3c0-0.9,0.6-1.5,1.2-1.5h28c0.7,0,1.2,0.7,1.2,1.5v4.3
                                                                C132.2,319.8,131.7,320.5,131,320.5z M103,313.8c-0.2,0-0.5,0.3-0.5,0.8v4.3c0,0.5,0.3,0.8,0.5,0.8h28c0.2,0,0.5-0.3,0.5-0.8
                                                                v-4.3c0-0.5-0.3-0.8-0.5-0.8H103z" />
                                            </g>
                                            <g>
                                                <path class="st3" d="M131,320.9h-28c-0.9,0-1.7-0.9-1.7-2v-4.3c0-1.1,0.8-2,1.7-2h28c0.9,0,1.7,0.9,1.7,2v4.3
                                                                C132.7,320,131.9,320.9,131,320.9z M103,314.3c0,0-0.1,0.2-0.1,0.4v4.3c0,0.2,0.1,0.4,0.1,0.4l28,0c0,0,0.1-0.1,0.1-0.4v-4.3
                                                                c0-0.2-0.1-0.4-0.1-0.4L103,314.3z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M106,296.7c0.5,0.1,0.9,0.1,1.4,0.2c0.5,0.1,0.5-0.7,0-0.8c-0.5-0.1-0.9-0.1-1.4-0.2
                                                        C105.5,295.9,105.5,296.7,106,296.7L106,296.7z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M108,290.6c0,0.2,0,0.5,0,0.7c0,0.5,0.8,0.5,0.8,0c0-0.2,0-0.5,0-0.7C108.8,290.1,108,290.1,108,290.6
                                                        L108,290.6z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M110.7,297.8c0.3-0.2,0.7-0.4,1-0.6c0.4-0.2,0-0.9-0.4-0.7c-0.3,0.2-0.7,0.4-1,0.6
                                                        C109.9,297.3,110.3,298,110.7,297.8L110.7,297.8z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M106.7,306.7c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0-1-0.4-0.7c-0.4,0.3-0.8,0.6-1.2,0.9
                                                        C105.9,306.3,106.3,307,106.7,306.7L106.7,306.7z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M103.8,300c0.3,0.1,0.6,0.2,0.9,0.3c0.5,0.2,0.7-0.6,0.2-0.8c-0.3-0.1-0.6-0.2-0.9-0.3
                                                        C103.6,299.1,103.4,299.8,103.8,300L103.8,300z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M109.5,303.6c0.2,0,0.3,0.1,0.4,0.2c0.3,0.4,0.9-0.2,0.6-0.5c-0.2-0.3-0.6-0.4-1-0.5
                                                        C109,302.8,109,303.6,109.5,303.6L109.5,303.6z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M111.2,307.3c-0.1,0.5,0,1,0.2,1.5c0.2,0.5,0.9,0.1,0.7-0.4c-0.1-0.3-0.2-0.6-0.1-0.9
                                                        C112,307,111.3,306.8,111.2,307.3L111.2,307.3z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M115.2,307.4c0.2,0.1,0.5,0.1,0.7,0.2c0.5,0.1,0.7-0.6,0.2-0.8c-0.2-0.1-0.5-0.1-0.7-0.2
                                                        C115,306.6,114.8,307.3,115.2,307.4L115.2,307.4z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M117.7,299.8c0,0.4,0.1,0.8,0.5,1.1c0.4,0.3,0.9-0.2,0.6-0.5c-0.2-0.2-0.3-0.3-0.2-0.6
                                                        C118.6,299.3,117.8,299.3,117.7,299.8L117.7,299.8z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st9" d="M116.7,294.2c-0.2,0.3-0.3,0.6-0.2,1c0.1,0.5,0.8,0.3,0.8-0.2c0-0.2,0-0.3,0.1-0.4
                                                        C117.7,294.2,117,293.8,116.7,294.2L116.7,294.2z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M121,296.5c0,0.2,0,0.5,0,0.7c0,0.5,0.8,0.5,0.8,0c0-0.2,0-0.5,0-0.7C121.8,296,121,296,121,296.5
                                                        L121,296.5z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M113.8,295.3c0.2,0.1,0.3,0.2,0.5,0.3c0.4,0.3,0.8-0.4,0.4-0.7c-0.2-0.1-0.3-0.2-0.5-0.3
                                                        C113.7,294.3,113.4,295,113.8,295.3L113.8,295.3z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M115.1,288.8c0.2,0.2,0.2,0.4,0.2,0.6c0,0.5,0.8,0.5,0.8,0c0-0.4-0.2-0.8-0.5-1.1
                                                        C115.3,287.9,114.8,288.5,115.1,288.8L115.1,288.8z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2"
                                            d="M117,286.5c0,0.3,0,0.7,0,1c0,0.5,0.8,0.5,0.8,0c0-0.3,0-0.7,0-1C117.8,286,117,286,117,286.5L117,286.5z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M121.8,291.1c0.1,0.1,0.2,0.2,0.3,0.4c0.3,0.4,0.9-0.2,0.5-0.6c-0.1-0.1-0.2-0.2-0.3-0.4
                                                        C122.1,290.1,121.5,290.7,121.8,291.1L121.8,291.1z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M122.6,286.4c0.3,0,0.7,0,1,0.1c0.5,0,0.5-0.7,0-0.8c-0.3,0-0.7,0-1-0.1
                                                        C122.1,285.6,122.1,286.4,122.6,286.4L122.6,286.4z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M123.7,280.2c0.2,0.2,0.5,0.5,0.7,0.7c0.4,0.4,0.9-0.2,0.5-0.5c-0.2-0.2-0.5-0.5-0.7-0.7
                                                        C123.9,279.3,123.4,279.8,123.7,280.2L123.7,280.2z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M126.7,278.2c0,0.4,0,0.8,0,1.2c0,0.5,0.8,0.5,0.8,0c0-0.4,0-0.8,0-1.2
                                                        C127.5,277.7,126.7,277.7,126.7,278.2L126.7,278.2z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M128.1,285.5c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.4-0.4-0.8-0.7-0.4c-0.1,0.2-0.2,0.3-0.3,0.5
                                                        C127.2,285.5,127.9,285.9,128.1,285.5L128.1,285.5z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M125.5,293.1c0.3-0.1,0.6-0.2,0.9-0.3c0.5-0.2,0.3-0.9-0.2-0.8c-0.3,0.1-0.6,0.2-0.9,0.3
                                                        C124.8,292.5,125,293.3,125.5,293.1L125.5,293.1z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M128.9,292c0.3-0.1,0.6-0.1,0.9-0.2c0.5-0.1,0.3-0.9-0.2-0.8c-0.3,0.1-0.6,0.1-0.9,0.2
                                                        C128.2,291.4,128.4,292.1,128.9,292L128.9,292z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M126.7,295.6c0,0.1,0,0.1,0,0.2c0,0.5,0.8,0.5,0.8,0c0-0.1,0-0.1,0-0.2
                                                        C127.5,295.1,126.7,295.1,126.7,295.6L126.7,295.6z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M120,307.6c0.2-0.1,0.3-0.2,0.5-0.3c0.4-0.3,0-1-0.4-0.7c-0.2,0.1-0.3,0.2-0.5,0.3
                                                        C119.2,307.2,119.6,307.8,120,307.6L120,307.6z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M119.6,310.4c0.3-0.3,0.6-0.5,1.1-0.6c0.5-0.1,0.3-0.9-0.2-0.8c-0.5,0.1-1,0.4-1.4,0.8
                                                        C118.7,310.2,119.2,310.8,119.6,310.4L119.6,310.4z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M116.4,310.9c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.4,0.9,0.1,0.7-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
                                                        C116.8,310,116.2,310.4,116.4,310.9L116.4,310.9z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M113.4,311.7c0.4,0.2,0.9,0.3,1.3,0.2c0.5-0.1,0.3-0.8-0.2-0.8c-0.3,0.1-0.5,0-0.7-0.1
                                                        C113.4,310.8,113,311.5,113.4,311.7L113.4,311.7z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st2" d="M119.8,304.3c0.5-0.1,1-0.1,1.6-0.2c0.5-0.1,0.5-0.8,0-0.8c-0.5,0.1-1,0.1-1.6,0.2
                                                        C119.4,303.6,119.4,304.4,119.8,304.3L119.8,304.3z" />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path class="st10" d="M189.5,270.3c8.4-7.6,16.7-15.1,25.1-22.7c1.2-1.1-0.6-3-1.8-1.8c-8.4,7.6-16.7,15.1-25.1,22.7
                                                        C186.4,269.5,188.3,271.4,189.5,270.3L189.5,270.3z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st10" d="M217.9,281.8c8.4-7.6,16.7-15.1,25.1-22.7c1.2-1.1-0.6-3-1.8-1.8c-8.4,7.6-16.7,15.1-25.1,22.7
                                                        C214.8,281.1,216.7,283,217.9,281.8L217.9,281.8z" />
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path class="st10" d="M187.1,290.4c17.5-17.1,35.1-34.2,52.6-51.3c1.2-1.2-0.6-3-1.8-1.8c-17.5,17.1-35.1,34.2-52.6,51.3
                                                        C184,289.8,185.9,291.6,187.1,290.4L187.1,290.4z" />
                                    </g>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <rect x="266.4" y="130.8" class="st3" width="1.1" height="72.4" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <circle class="st3" cx="266.9" cy="203.3" r="4.6" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path class="st3" d="M267,218.7c-0.1,0-1.6,0-2.7-1.1c-0.7-0.7-1.1-1.7-1.1-3c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6
                                                                c0,0.9,0.3,1.7,0.7,2.2c0.7,0.7,1.8,0.8,1.8,0.8c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1c-0.3,0-0.6-0.3-0.6-0.6v-3.4
                                                                c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v2.8c2.1,0.3,3.7,2.1,3.7,4.3C271.3,216.7,269.4,218.7,267,218.7
                                                                C267,218.7,267,218.7,267,218.7z" />
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st3"
                                                    d="M242.9,221.5c-0.2,0-0.4-0.2-0.5-0.5c0-0.3,0.2-0.6,0.5-0.6l24.9-3.7c0.3,0,0.6,0.2,0.6,0.5
                                                                c0,0.3-0.2,0.6-0.5,0.6l-24.9,3.7C243,221.5,242.9,221.5,242.9,221.5z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path class="st3"
                                                    d="M290.6,229.8c-0.1,0-0.1,0-0.2-0.1l-22.9-11.9c-0.3-0.1-0.4-0.5-0.2-0.8c0.1-0.3,0.5-0.4,0.8-0.2
                                                                l22.9,11.9c0.3,0.1,0.4,0.5,0.2,0.8C291.1,229.7,290.9,229.8,290.6,229.8z" />
                                            </g>
                                        </g>
                                        <g>
                                            <path class="st0"
                                                d="M288.8,277.6l-61-10.6c-3.2-0.6-5.3-3.6-4.8-6.8l6.1-35.1c0.6-3.2,3.6-5.3,6.8-4.8l61,10.6
                                                            c3.2,0.6,5.3,3.6,4.8,6.8l-6.1,35.1C295,276,292,278.1,288.8,277.6z" />
                                            <path class="st3" d="M289.6,278.4c-0.4,0-0.8,0-1.2-0.1l-60.5-10.5c-1.8-0.3-3.4-1.3-4.4-2.8c-1.1-1.5-1.5-3.3-1.1-5.1l6-34.7
                                                            c0.6-3.7,4.2-6.2,7.9-5.6l60.5,10.5c1.8,0.3,3.4,1.3,4.4,2.8c1.1,1.5,1.5,3.3,1.2,5.1l-6,34.7
                                                            C295.8,276,292.9,278.4,289.6,278.4z M235.1,221c-2.5,0-4.8,1.8-5.2,4.4l-6,34.7c-0.2,1.4,0.1,2.8,0.9,4
                                                            c0.8,1.2,2,1.9,3.4,2.2l60.5,10.5c2.9,0.5,5.6-1.4,6.1-4.3l6-34.7c0.2-1.4-0.1-2.8-0.9-4c-0.8-1.2-2-1.9-3.4-2.2L236,221.1
                                                            C235.7,221.1,235.4,221,235.1,221z" />
                                        </g>
                                        <g>
                                            <path class="st6"
                                                d="M288.3,273.8l-58.8-10.2c-2-0.4-3.4-2.3-3.1-4.3l5.6-32c0.4-2,2.3-3.4,4.3-3.1l58.8,10.2
                                                            c2,0.4,3.4,2.3,3.1,4.3l-5.6,32C292.3,272.8,290.3,274.1,288.3,273.8z" />
                                            <path class="st3" d="M288.8,274.6c-0.3,0-0.5,0-0.8-0.1l-58.5-10.2c-1.2-0.2-2.3-0.9-3-1.9c-0.7-1-1-2.3-0.8-3.5l5.5-31.8
                                                            c0.4-2.5,2.9-4.2,5.4-3.8l58.5,10.2c2.5,0.4,4.2,2.9,3.8,5.4l-5.5,31.8c-0.2,1.2-0.9,2.3-1.9,3
                                                            C290.7,274.3,289.8,274.6,288.8,274.6z M288.3,273c0.8,0.1,1.6,0,2.3-0.5c0.7-0.5,1.1-1.2,1.3-2l5.5-31.8
                                                            c0.3-1.7-0.8-3.3-2.5-3.6l-58.5-10.2c-1.7-0.3-3.3,0.8-3.6,2.5l-5.5,31.8c-0.1,0.8,0,1.6,0.5,2.3c0.5,0.7,1.2,1.1,2,1.3
                                                            L288.3,273z" />
                                        </g>
                                        <g>
                                            <polygon class="st0" points="260.4,268.9 275.6,255 284,273 						" />
                                            <path class="st11"
                                                d="M284.9,273.7l-25.6-4.5l16.5-15.1L284.9,273.7z M261.5,268.6l21.6,3.8l-7.7-16.5L261.5,268.6z" />
                                        </g>
                                        <g>
                                            <polygon class="st0" points="230.3,263.7 257.3,239 272.2,271 						" />
                                            <path class="st11"
                                                d="M273.1,271.7l-43.9-7.6l28.3-25.9L273.1,271.7z M231.5,263.4l39.9,6.9l-14.2-30.5L231.5,263.4z" />
                                        </g>
                                        <g>
                                            <ellipse transform="matrix(0.1712 -0.9852 0.9852 0.1712 -2.6783 482.8634)"
                                                class="st0" cx="285.7" cy="243" rx="3.8" ry="3.8" />
                                            <path class="st11" d="M285.7,247.3c-0.2,0-0.5,0-0.7-0.1c-2.3-0.4-3.9-2.6-3.5-5c0.2-1.1,0.8-2.1,1.8-2.8
                                                            c0.9-0.7,2.1-0.9,3.2-0.7c1.1,0.2,2.1,0.8,2.8,1.8c0.7,0.9,0.9,2.1,0.7,3.2c-0.2,1.1-0.8,2.1-1.8,2.8
                                                            C287.4,247,286.5,247.3,285.7,247.3z M285.7,239.8c-0.7,0-1.3,0.2-1.9,0.6c-0.7,0.5-1.2,1.2-1.3,2.1c-0.1,0.9,0,1.7,0.5,2.4
                                                            c0.5,0.7,1.2,1.2,2.1,1.3c0.9,0.1,1.7,0,2.4-0.5c0.7-0.5,1.2-1.2,1.3-2.1l0,0c0.3-1.8-0.9-3.5-2.6-3.8
                                                            C286,239.8,285.8,239.8,285.7,239.8z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <rect x="257" y="128.4" class="st6" width="56.5" height="6.2" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <path class="st3"
                                                                d="M362.7,257h-18.8V91.1h18.8V257z M345.8,255.1h15.1V92.9h-15.1V255.1z" />
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M362.5,109.3h-18.3V91h18.3V109.3z M346,107.4h14.5V92.9H346V107.4z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="352.4" y="88.5"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 32.6823 279.153)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="341.7" y="99.2"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 32.6828 279.1539)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M362.5,125.7h-18.3v-18.3h18.3V125.7z M346,123.8h14.5v-14.5H346V123.8z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="352.4" y="104.9"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 21.0762 283.9615)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="341.7" y="115.6"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 21.0762 283.9615)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M362.5,142.1h-18.3v-18.3h18.3V142.1z M346,140.2h14.5v-14.5H346V140.2z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="352.4" y="121.3"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 9.4686 288.7671)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="341.7" y="132"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 9.4754 288.7821)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M362.5,158.5h-18.3v-18.3h18.3V158.5z M346,156.6h14.5v-14.5H346V156.6z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="352.4" y="137.8"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1376 293.5757)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="341.7" y="148.4"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1371 293.5768)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M362.5,174.9h-18.3v-18.3h18.3V174.9z M346,173h14.5v-14.5H346V173z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="352.4" y="154.2"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.7438 298.3845)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="341.7" y="164.8"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.7388 298.3983)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M362.5,191.3h-18.3V173h18.3V191.3z M346,189.5h14.5v-14.5H346V189.5z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="352.4" y="170.6"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -25.3512 303.1898)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="341.7" y="181.3"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -25.3459 303.2063)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M362.5,207.8h-18.3v-18.3h18.3V207.8z M346,205.9h14.5v-14.5H346V205.9z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="352.4" y="187"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -36.9571 307.9997)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="341.7" y="197.7"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -36.953 308.0144)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M362.5,224.2h-18.3v-18.3h18.3V224.2z M346,222.3h14.5v-14.5H346V222.3z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="352.4" y="203.4"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -48.564 312.8061)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="341.7" y="214.1"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -48.5601 312.8225)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M362.5,240.6h-18.3v-18.3h18.3V240.6z M346,238.7h14.5v-14.5H346V238.7z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="352.4" y="219.8"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -60.1704 317.615)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="341.7" y="230.5"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -60.1671 317.6305)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M362.5,257h-18.3v-18.3h18.3V257z M346,255.1h14.5v-14.5H346V255.1z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="352.4" y="236.2"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -71.7776 322.4187)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="341.7" y="246.9"
                                                                    transform="matrix(0.7072 -0.707 0.707 0.7072 -71.791 322.3427)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M395.1,125.4h-18.3v-18.3h18.3V125.4z M378.7,123.6h14.5V109h-14.5V123.6z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="374.4" y="115.4"
                                                                    transform="matrix(0.707 -0.7072 0.7072 0.707 30.8533 307.0509)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="385" y="104.7"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 30.8168 306.983)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M378.7,125.4h-18.3v-18.3h18.3V125.4z M362.3,123.6h14.5V109h-14.5V123.6z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="358" y="115.4"
                                                                    transform="matrix(0.707 -0.7072 0.7072 0.707 26.0435 295.4421)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="368.6" y="104.7"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 26.0087 295.3754)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <path class="st3"
                                                                d="M362.3,125.4H344v-18.3h18.3V125.4z M345.9,123.6h14.5V109h-14.5V123.6z" />
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M345.9,125.4h-18.3v-18.3h18.3V125.4z M329.5,123.6H344V109h-14.5V123.6z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="325.1" y="115.4"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 16.3945 272.1641)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="335.8" y="104.7"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 16.3936 272.1622)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M329.5,125.4h-18.3v-18.3h18.3V125.4z M313,123.6h14.5V109H313V123.6z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="308.7" y="115.4"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 11.5864 260.5565)"
                                                                    class="st3" width="23.2" height="1.9" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="319.4" y="104.7"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 11.5873 260.5583)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M313,125.4h-18.3v-18.3H313V125.4z M296.6,123.6h14.5V109h-14.5V123.6z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <path class="st3"
                                                                    d="M295.7,125.4h-16.4c-0.4,0-0.7-0.2-0.9-0.6c-0.1-0.4-0.1-0.8,0.2-1l16.4-16.4l1.3,1.3l-14.8,14.8
                                                                                h13.8l16.1-16.1l1.3,1.3l-16.4,16.4C296.2,125.3,295.9,125.4,295.7,125.4z" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <rect x="303" y="104.7"
                                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 6.7784 248.949)"
                                                                    class="st3" width="1.9" height="23.2" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path class="st3"
                                                            d="M362.7,275.3h-18.8V257h18.8V275.3z M345.9,273.4h14.9v-14.5h-14.9V273.4z" />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <polygon class="st3"
                                                            points="361.1,275 344.2,258.6 345.6,257.3 362.5,273.7 									" />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <polygon class="st3"
                                                            points="345.6,275 344.2,273.7 361.1,257.3 362.5,258.6 									" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path class="st3"
                                                            d="M362.7,340.9h-18.8v-18.3h18.8V340.9z M345.9,339.1h14.9v-14.5h-14.9V339.1z" />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <polygon class="st3"
                                                            points="361.1,340.7 344.2,324.2 345.6,322.9 362.5,339.3 									" />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <polygon class="st3"
                                                            points="345.6,340.7 344.2,339.3 361.1,322.9 362.5,324.2 									" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path class="st3"
                                                        d="M362.7,290.1h-18.8v-18.3h18.8V290.1z M345.9,288.3h14.9v-14.5h-14.9V288.3z" />
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <polygon class="st3"
                                                        points="361.1,289.9 344.2,273.4 345.6,272.1 362.5,288.5 								" />
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <polygon class="st3"
                                                        points="345.6,289.9 344.2,288.5 361.1,272.1 362.5,273.4 								" />
                                                </g>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path class="st3"
                                                        d="M362.7,306.5h-18.8v-18.3h18.8V306.5z M345.9,304.7h14.9v-14.5h-14.9V304.7z" />
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <polygon class="st3"
                                                        points="361.1,306.3 344.2,289.9 345.6,288.5 362.5,304.9 								" />
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <polygon class="st3"
                                                        points="345.6,306.3 344.2,304.9 361.1,288.5 362.5,289.9 								" />
                                                </g>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path class="st3"
                                                        d="M362.7,323h-18.8v-18.3h18.8V323z M345.9,321.1h14.9v-14.5h-14.9V321.1z" />
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <polygon class="st3"
                                                        points="361.1,322.7 344.2,306.3 345.6,304.9 362.5,321.4 								" />
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <polygon class="st3"
                                                        points="345.6,322.7 344.2,321.4 361.1,304.9 362.5,306.3 								" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <path class="st3" d="M152.9,189" />
                                <g>
                                    <path class="st3"
                                        d="M152.9,190.5c2,0,2-3.1,0-3.1C150.9,187.4,150.9,190.5,152.9,190.5L152.9,190.5z" />
                                </g>
                            </g>
                            <g>
                                <path class="st3" d="M321.6,156.5" />
                                <g>
                                    <path class="st3"
                                        d="M321.6,158c2,0,2-3.1,0-3.1C319.6,154.9,319.6,158,321.6,158L321.6,158z" />
                                </g>
                            </g>
                            <g>
                                <path class="st3" d="M391.4,267.8c0.2,0,0.5,0,0.7,0" />
                                <g>
                                    <path class="st3"
                                        d="M391.4,269.4c0.2,0,0.5,0,0.7,0c0.8,0,1.6-0.7,1.6-1.6c0-0.8-0.7-1.6-1.6-1.6c-0.2,0-0.5,0-0.7,0
                                                    c-0.8,0-1.6,0.7-1.6,1.6C389.9,268.6,390.5,269.4,391.4,269.4L391.4,269.4z" />
                                </g>
                            </g>
                            <g>
                                <path class="st3" d="M91.3,290.6" />
                                <g>
                                    <path class="st3"
                                        d="M91.3,292.2c2,0,2-3.1,0-3.1C89.3,289.1,89.3,292.2,91.3,292.2L91.3,292.2z" />
                                </g>
                            </g>
                            <g>
                                <path class="st3" d="M240,141.3" />
                                <g>
                                    <path class="st3"
                                        d="M240,142.8c2,0,2-3.1,0-3.1C238,139.7,238,142.8,240,142.8L240,142.8z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    </section>