
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Footer } from 'src/app/models/footer';
import { FuneralFund } from 'src/app/models/funeralFund';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class services {
    path = environment.apiUrl;
    languageCode: string = '';
    sliderId = 2;

    constructor(
        private http: HttpClient) {
        this.languageCode = localStorage.getItem('language');
    }

    // ~~~~~~~~~~~~~~~~~~ Announcement~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllAnnouncements(): Observable<any> {
        const url = this.path + `FrontAnnouncement/GetList/${this.languageCode}`;
        return this.http.get(url);

    }

    getFrontAnnouncementByCode(code): Observable<any> {
        const url = this.path + `FrontAnnouncement/Get/${code}`;
        return this.http.get(url);
    }

    // ~~~~~~~~~~~~~~~~~~ Clergy~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllClergy(): Observable<any> {
        const url = this.path + `Clergy/GetAll`;
        return this.http.get(url);

    }

    // ~~~~~~~~~~~~~~~~~~ Activity~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllActivity(): Observable<any> {
        const url = this.path + `Activity/GetAllActivities`;
        return this.http.get(url);

    }

    // ~~~~~~~~~~~~~~~~~~ Mosque~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllMosque(): Observable<any> {
        const url = this.path + `Mosque/GetAll`;
        return this.http.get(url);

    }


    // ~~~~~~~~~~~~~~~~~~ Service~~~~~~~~~~~~~~~~~~~~~ //

    getFrontAllService(): Observable<any> {
        const url = this.path + `Service/GetList`;
        return this.http.get(url);

    }


    // ~~~~~~~~~~~~~~~~~~ Funeral Fund~~~~~~~~~~~~~~~~~~~~~ //

    addFamily(member: any): Observable<FuneralFund> {
        const url = this.path + "Family/FuneralFundApplication";
        return this.http.post<FuneralFund>(url, member);
    }


    // ~~~~~~~~~~~~~~~~~~Homepage Small Announcement~~~~~~~~~~~~~~~~~~~~~ //

    getAllSmallAnnouncement(): Observable<any> {
        const url = this.path + `GetAllSmallAnnouncement?languageCode=${this.languageCode}`;
        return this.http.get(url);
    }


    // ~~~~~~~~~~~~~~~~~~Association~~~~~~~~~~~~~~~~~~~~~ //

    getAssociation(): Observable<any> {
        const url = this.path + `Association/GetAllAssociations`;
        return this.http.get(url);
    }


}
