import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { PageService } from '../../../services/page.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class WebPagesResolver implements Resolve<any> {
    constructor(private pageService: PageService, private router: Router) { }
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {
        const id = route.paramMap.get('id');
        return this.pageService.getContentPage(id).pipe(
            catchError((error) => {
                this.router.navigate(['/']);
                return of(null);
            })
        );
    }
}