

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>Hata</li>
            </ul>
            <h2>Hata 404</h2>
        </div>
    </div>
</div>

<div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h1>Oops!</h1>
            <h3>Hata 404 : Sayfa Bulunamadı</h3>
            <p>Aradığınız sayfa kaldırılmış, adı değiştirilmiş veya geçici olarak kullanılamıyor olabilir.</p>
            <a routerLink="/" class="default-btn"><i class='bx bx-home-circle icon-arrow before'></i><span class="label">Anasayfaya dön</span><i class="bx bx-home-circle icon-arrow after"></i></a>
        </div>
    </div>
</div>