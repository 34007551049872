<div class="banner-wrapper">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="wrapper-content">
                    <h1>{{'DONATE'|translate}}</h1>
                    <p>{{'SUPPORT_BY_DONATING'|translate}}
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="banner-courses-category" >
                    <ul>
                        <li *ngFor="let item of donationCategory">
                            <a routerLink="/bagis/{{item.id}}" [class.mainDonation]="item.id===donateDetail.id">
                                <i [class]="item.folderPath"></i>
                                {{item.title}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="checkout-area pt-100  pb-100">
    <div class="container">
        <div class="billing-details form-area">
            <div class="row">
                <div class="col-md-6 " style="margin:auto">
                    <div class="wrapper" id="app">
                        <div class="card-form">
                            <form [formGroup]="donationForm">
                                <div class="row">

                                    <h3 class="title">{{'PERSONAL_INFORMATION'|translate}}</h3>
                                    <div class="row">
                                        <!-- <div class="card-input col-md-6">
                                            <label for="cardNumber" class="card-input__label">Ad Soyad</label>
                                            <input type="text" formControlName="fullName" id="cardNumber"
                                                placeholder="Ad Soyad" class="card-input__input">
                                        </div>

                                        <div class="card-input col-md-6">
                                            <label for="adddress" class="card-input__label">Adres</label>
                                            <textarea type="text" formControlName="address" id="address"
                                                placeholder="Adres" class="card-input__input"></textarea>
                                        </div>
                                        <div class="card-input col-md-6">
                                            <label for="adddress" class="card-input__label">Telefon</label>
                                            <textarea type="text" formControlName="address" id="address"
                                                placeholder="Adres" class="card-input__input"></textarea>
                                        </div> -->

                                        <div class="card-input col-md-12">
                                            <div id="card-address">
                                                <!-- Stripe card address input -->
                                            </div>
                                        </div>
                                        <div class="card-input col-md-12">
                                            <label for="email" class="card-input__label">{{'EMAIL'|translate}}</label>
                                            <input type="text" id="email" formControlName="email" placeholder="Email"
                                                class="card-input__input"
                                                [ngClass]="{ 'Input--invalid': donationForm?.get('email')?.errors?.['required'] && donationForm?.get('email')?.invalid && donationForm?.get('email')?.touched }">
                                            <div
                                                *ngIf="(donationForm.get('email').errors?.required && donationForm.get('email').touched) || 
                                                (donationForm.get('email').errors?.email && donationForm.get('email').touched)">
                                                <p *ngIf="donationForm.get('email').errors?.required" class="Error">
                                                    {{'THIS_FIELD_IS_MISSING' | translate}}
                                                </p>
                                                <p *ngIf="donationForm.get('email').errors?.email" class="Error">
                                                    {{'INVALID_EMAIL_FORMAT' | translate}}
                                                </p>
                                            </div>

                                        </div>
                                        <div class="card-input col-md-12  ">
                                            <label for="cardNumber"
                                                class="card-input__label">{{'DONATION_AMOUNT'|translate}}</label>
                                            <div class="input-group amount">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">CHF</span>
                                                </div>
                                                <input type="text" id="cardNumber" placeholder="00.000,00"
                                                    appCurrencyInput formControlName="amount" aria-label="Amount"
                                                    class="card-input__input" autocomplete="off"
                                                    [ngClass]="{ 'Input--invalid': donationForm?.get('amount')?.errors?.['required'] && donationForm?.get('amount')?.invalid && donationForm?.get('amount')?.touched}">
                                            </div>
                                            <div
                                                *ngIf="donationForm.get('amount').errors?.required && donationForm.get('amount').touched">
                                                <p class="Error">{{'THIS_FIELD_IS_MISSING'|translate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="title">{{'CARD_INFORMATION'|translate}}</h3>

                                    <!-- <div class="card-list ">
                                        <div class="card-item">
                                            <div class="card-item__side -front"
                                                [ngStyle]="{ 'display': !cardFlip ? 'block' : 'none', 'transform': cardFlip ? 'none' : 'transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);' }">
                                                <div class="card-item__cover">
                                                    <img src="assets/img/donation/banner/creditcard.png"
                                                        class="card-item__bg">
                                                </div>
                                                <div class="cardNumber">
                                                    #### #### #### ####
                                                </div>
                                                <div class="card-item__wrapper">


                                                    <div class="card-item__content">

                                                        <label for="cardName" class="card-item__info" ref="cardName">
                                                            <div class="card-item__name">Ad Soyad</div>
                                                        </label>
                                                        <div class="card-item__date" ref="cardDate">
                                                            <label for="cardMonth"
                                                                class="card-item__dateTitle">Geçerlilik
                                                                Tarihi</label>
                                        
                                                            <label for="cardMonth" class="card-item__dateItem">
                                                                <span>Ay</span>
                                                            </label>/
                                                            <label for="cardYear" class="card-item__dateItem">
                                                                <span> Yıl</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-item__side -back "
                                                [ngStyle]="{ 'display': cardFlip ? 'block' : 'none', 'transform': cardFlip ? 'none' : 'transition: all 0.8s cubic-bezier(0.71, 0.03, 0.creditcard6, 0.85);' }">
                                                <div class="card-item__cover">
                                                    <img style=" transform: scaleX(-1) scaleY(-1);"
                                                        src="assets/img/donation/banner/creditcard.png"
                                                        class="card-item__bg">
                                                </div>
                                                <div class="card-item__band"></div>
                                                <div class="card-item__cvv">
                                                    <div class="card-item__cvvTitle">CVV</div>
                                                    <div class="card-item__cvvBand">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="row">


                                        <div class="card-input ">
                                            <label for="cardName"
                                                class="card-input__label">{{'FULL_NAME'|translate}}</label>
                                            <input placeholder="Ad Soyad" type="text" id="cardName"
                                                formControlName="cardFullName" class="card-input__input"
                                                [ngClass]="{ 'Input--invalid': donationForm?.get('cardFullName')?.errors?.['required'] && donationForm?.get('cardFullName')?.invalid && donationForm?.get('cardFullName')?.touched}">
                                            <div
                                                *ngIf="donationForm.get('cardFullName').errors?.required && donationForm.get('cardFullName').touched">
                                                <p class="Error">{{'THIS_FIELD_IS_MISSING'|translate}}</p>
                                            </div>
                                        </div>

                                        <!-----------Stripe Elements---------------->

                                        <div class="card-input">
                                            <label for="cardNumber"
                                                class="card-input__label">{{'CARD_NUMBER'|translate}}</label>
                                            <div id="card-number" #cardNumberElement class="card-input__input">
                                                <!-- Stripe card number input -->
                                                <div id="card-number-errors" class="error-message"></div>
                                            </div>
                                        </div>
                                        <div class="card-form__row">

                                            <div class="card-form__col">
                                                <div class="card-form__group">

                                                    <div class="card-form__col">
                                                        <div class="card-input">
                                                            <label for="cardMonth"
                                                                class="card-input__label">{{'EXPIRY_DATE'|translate}}</label>
                                                            <div id="card-expiry" class="card-input__input">
                                                                <!-- Stripe Expiry card input -->

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card-form__col -cvv">
                                                        <div class="card-input">
                                                            <label for="cardCvc"
                                                                class="card-input__label">{{'CVC'|translate}}</label>
                                                            <div id="card-Cvc" class="card-input__input">
                                                                <!-- Stripe Cvc input -->
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <!-- Stripe errors. -->
                                        <div id="card-errors" role="alert"></div>

                                        <button class="card-form__button" type="button" [disabled]="loading"
                                            (click)="saveForm()">
                                            <span role="status" *ngIf="!loading">{{'DONATE'|translate}}</span>
                                            <div *ngIf="loading" class="spinner-border spinner-border-m" role="status">
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="post-header">
                        <h1>{{donateDetail.title}}</h1>
                        <p [innerHTML]="donateDetail.description"> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>