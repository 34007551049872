import { Component, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
	Stripe,
	StripeAddressElement,
	StripeAddressElementOptions,
	StripeCardCvcElement,
	StripeCardCvcElementOptions,
	StripeCardExpiryElement,
	StripeCardExpiryElementOptions,
	StripeCardNumberElement,
	StripeCardNumberElementOptions,
	StripeElements
} from '@stripe/stripe-js';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { Content } from 'src/app/models/content';
import { ContentService } from 'src/app/services/content.service';
import { OnlineDonateService } from 'src/app/services/onlineDonate.service';
import { StripeService } from 'src/app/services/stripe.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
	selector: 'app-donation',
	templateUrl: './donation.component.html',
	styleUrls: ['./donation.component.scss']
})
export class DonationComponent implements OnInit, OnDestroy {
	donationForm: FormGroup
	cardFlip: boolean = false;
	donateDetail: Content
	donationCategory: Content[] = [];
	imgUrl: string = environment.imgUrl;
	loading: boolean = false;
	navigationSubscription: any;
	categoriesSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 3
			},
			1200: {
				items: 4
			}
		}
	}

	constructor(private router: Router, private fb: FormBuilder, private activeRoute: ActivatedRoute, private contentService: ContentService, private stripeService: StripeService, private donateService: OnlineDonateService, private translationService: TranslationService) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	initialiseInvites() {
		this.ngOnInit();
	}

	ngOnDestroy() {
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	ngOnInit(): void {
		this.activeRoute.data.subscribe(
			(data: { data: any }) => {
				if (data.data) {
					this.donateDetail = data.data.data;
				}
			}
		);
		this.createDonationForm();

		this.contentService.getAllDonations().subscribe((data: any) => {
			this.donationCategory = data.data
		})

		this.setupStripe();

	}



	createDonationForm() {
		this.donationForm = this.fb.group({
			token: [''],
			amount: ['', Validators.required],
			currency: ['CHF'],
			description: this.donateDetail.title,
			email: ['', [Validators.required, Validators.email]],
			categoryId: this.donateDetail.id,
			cardFullName: ['', Validators.required],
			fullName: ['', Validators.required],
			phoneNumber: ['', Validators.required],
			address: ['', Validators.required],
		})
	}

	// flipCardBack() {
	// 	this.cardFlip = true;
	// }

	// flipCardFront() {
	// 	this.cardFlip = false;
	// }


	// ~~~~~~~~~~~~~~~~~ STRIPE ~~~~~~~~~~~~~~~~~~~~~~ //

	stripe: Stripe | null = null;
	elements: StripeElements | null = null;
	cardNumber: StripeCardNumberElement | null = null;
	cardExpiry: StripeCardExpiryElement | null = null;
	cardCvc: StripeCardCvcElement | null = null;
	cardAddress: StripeAddressElement | null = null;

	async setupStripe() {
		
		this.stripe = await this.stripeService.getStripe(this.donateDetail.id);
		if (this.stripe) {
			this.elements = this.stripe.elements();


			const styles = {
				style: {
					base: {
						color: '#32325d',
						fontFamily: '"Source Sans Pro", sans-serif',
						fontSmoothing: 'antialiased',
						fontSize: '17px',

						'::placeholder': {
							color: 'darkgray'
						}
					},
					invalid: {
						color: '#fa755a',
						iconColor: '#fa755a'
					}
				}


			};

			const cardNumberStyle: StripeCardNumberElementOptions = {
				...styles,
				showIcon: true
			};

			const cardCvcStyle: StripeCardCvcElementOptions = {
				...styles
			};

			const cardExpiryStyle: StripeCardExpiryElementOptions = {
				...styles,
			};

			const addressStyle: StripeAddressElementOptions = {
				mode: 'billing',
				fields: {
					phone: 'always',
				},
				validation: {
					phone: {
						required: 'always'
					}
				}

			};


			this.cardNumber = this.elements.create('cardNumber', cardNumberStyle);
			this.cardNumber.mount('#card-number');

			this.cardExpiry = this.elements.create('cardExpiry', cardExpiryStyle);
			this.cardExpiry.mount('#card-expiry');

			this.cardCvc = this.elements.create('cardCvc', cardCvcStyle);
			this.cardCvc.mount('#card-Cvc');

			this.cardAddress = this.elements.create('address', addressStyle);
			this.cardAddress.mount('#card-address');

		}
	}



	async saveForm() {
		this.loading = true;

		if (!this.stripe || !this.cardNumber) {
			this.loading = false;
			return;
		}

		const { name, phone, address: { line1, line2, city, state, postal_code, country } } = (await this.cardAddress.getValue()).value;

		this.donationForm.patchValue({
			fullName: name,
			phoneNumber: phone,
			address: `${line1} ${line2 || ''} ${city},${state},${postal_code},${country}`,
		})

		const form = this.donationForm.value;

		const tokenCardData = {
			currency: form.currency,
			address_line1: line1,
			address_line2: line2 || '',
			address_city: city,
			address_state: state,
			address_zip: postal_code,
			address_country: country,
			name: form.cardFullName,

		};

		if (!this.donationForm.valid) {
			this.loading = false;
			this.donationForm.markAllAsTouched();
			return;
		}
		
		const { token, error } = await this.stripe.createToken(this.cardNumber, tokenCardData)
		
		const sweetAlert = (isError: boolean): SweetAlertOptions => ({
			title: isError ? this.translationService.getValue('UNSUCCESS') : this.translationService.getValue('SUCCESS'),
			icon: isError ? "error" : "success",
			confirmButtonText: this.translationService.getValue('CLOSE'),
			confirmButtonColor: isError ? "#CF3339" : "#18988B"
		});

		if (error) {
			console.error('Error:', error);
			this.loading = false;
			Swal.fire({
				...sweetAlert(true),
				text: error.message
			});
		} else {

			form.token = token.id;

			this.donateService.createPayment(form).subscribe((data: any) => {

				if (data.success === true) {
					this.loading = false;
					Swal.fire({
						...sweetAlert(false),
						text: this.translationService.getValue('YOUR_DONATION_HAS_BEEN_SUCCESSFULLY_RECEIVED')
					}).then(() => {
						window.location.reload();
					});
				} else {
					this.loading = false;
					Swal.fire({
						...sweetAlert(true),
						text: data.statusMessage,
					});
				}


			}, (error) => {
				this.loading = false;

				let errorMessage = this.translationService.getValue('AN_ERROR_OCCURRED_DURING_YOUR_APPLICATION.PLEASE_TRY_AGAIN_LATER');

				if (error.error.errors[0]) {
					const getError = error.error.errors[0]
					if (getError.includes('Invalid email address')) {
						errorMessage = this.translationService.getValue('INVALID_EMAIL');
					} else if (getError.includes('Your card was declined')) {
						errorMessage = this.translationService.getValue('CARD_DECLINE');
					} else if (getError.includes('Your card has insufficient funds.')) {
						errorMessage = this.translationService.getValue('INSUFFICIENT_FUNDS');
					} else if (getError.includes('Your card has expired')) {
						errorMessage = this.translationService.getValue('EXPIRED_CARD');
					} else if (getError.includes('security code is incorrect.')) {
						errorMessage = this.translationService.getValue('INCORRECT_CVC');
					} else if (getError.includes('An error occurred while processing your card. Try again in a little bit.')) {
						errorMessage = this.translationService.getValue('PROCESSING_ERROR');
					} else if (getError.includes('Your card was declined for making repeated attempts')) {
						errorMessage = this.translationService.getValue('VELOCITY_LIMIT_EXCEEDED');
					}
				}

				Swal.fire({
					...sweetAlert(true),
					text: errorMessage,
				});

			}
			);
		}


	}


}