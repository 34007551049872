import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';
import { ContentService } from 'src/app/services/content.service';
import { HajjService } from 'src/app/services/hajj.service';
import { CheckBoxOption } from 'src/app/models/checkboxOption';
import { Content } from 'src/app/models/content';
import { HajjApplication } from 'src/app/models/hajjApplication';
import { MaritalStatus } from 'src/app/models/maritalStatus';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-hajj',
  templateUrl: './hajj.component.html',
  styleUrls: ['./hajj.component.scss']
})
export class HajjComponent implements OnInit {
  isLoading: boolean = false;
  personalInformationForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;
  otherForm: UntypedFormGroup;
  passportForm: UntypedFormGroup;
  roomTypes: any[] = [];
  gender: any[] = [];
  application: HajjApplication[] = [];
  isValid: boolean = false;
  isUsePermission: boolean = false;
  isRelativesJoin: boolean = false;
  isRegulation: boolean = false;
  photoUrl: string = environment.imgUrl
  isFormDisabled: boolean = true;
  maritalStatus: MaritalStatus[] = [];
  regulation: Content
  checkBoxOption = new CheckBoxOption().checkBoxArray;


  constructor(private fb: FormBuilder, private applicationService: ApplicationService, private router: Router,private hajjService:HajjService,private contentService:ContentService) { }

  ngOnInit(): void {
    this.createForm();
    this.getRoomTypes();
    this.getGender();
    this.getMaritalStatus();
    this.getRegulation();

  }


  getRegulation() {
    this.contentService.getRegulation().subscribe((data: any) => {
      this.regulation = data.data.find((item: any) => item.title === "hacyonetmeliktr").folderPath;

    })
  }
  
  getRoomTypes() {
    this.applicationService.getAllRoomTypes().subscribe((data: any) => {
      this.roomTypes = data.filter(room => room.typeName == 'Hac').sort((a, b) => a.roomTypes - b.roomTypes);
    });
  }

  getGender() {
    this.applicationService.getGender().subscribe((data: any) => {
      this.gender = data;
    });
  }

  getMaritalStatus() {
    this.applicationService.getMaritalStatus().subscribe((data: MaritalStatus[]) => {
      this.maritalStatus = data;
    });
  }

  checkYes(e, type) {
    if (type === 'isUsePermission') {
      this.isUsePermission = e.value;
      // (this.isUsePermission && this.otherForm.value.dateOfIstanbul == "") ? this.isFormDisabled = false : this.isFormDisabled = true;
    }
    else if (type === "isRegulation") {
      this.isRegulation = e.checked;
    }
    else {
      this.isRelativesJoin = e.value;
      // (this.isRelativesJoin && (this.otherForm.value.proximityDegree == "" || this.otherForm.value.relativeName == "")) ? this.isFormDisabled = false : this.isFormDisabled = true;
    }


  }

  changeValue(e: any, type) {
    // if (type === 'dateOfIstanbul') {
    //   (this.isUsePermission && this.otherForm.value.dateOfIstanbul == "") ? this.isFormDisabled = false : this.isFormDisabled = true
    // }
    // if (type === 'proximityDegree' || type === 'relativeName') {
    //   (this.isRelativesJoin && (this.otherForm.value.proximityDegree == "" || this.otherForm.value.relativeName == "")) ? this.isFormDisabled = false : this.isFormDisabled = true

    // }
  }

  checkForm(form: AbstractControl): boolean {
    this.isValid = form.dirty && form.valid && form.touched;
    return this.isValid;

  }

  nextTab(currentTabIndex: number) {
    currentTabIndex++;
    const tabId = `tab${currentTabIndex + 1}`;
    const tabRadio = document.getElementById(tabId) as HTMLInputElement;
    if (tabRadio) {
      tabRadio.click();
    }

   
  }

  createForm() {
    this.personalInformationForm = this.fb.group({
      fullName: ['', [Validators.required]],
      identificationNumber: ['', Validators.minLength(11)],
      genderId: ['', [Validators.required]],
      birthday: ['', [Validators.required]],
      birthPlace: [''],
      maritalStatusId: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    })

    this.addressForm = this.fb.group({
      adres: ['', [Validators.required]],
      city: ['', [Validators.required]],
      no: ['', [Validators.required]],
      plz: ['', [Validators.required]]
    })

    this.passportForm = this.fb.group({
      passportNo: ['', [Validators.required]],
      passportGivenDate: ['', [Validators.required]],
      passportExpirationDate: ['', [Validators.required]],
      passportType: ['', [Validators.required]],
    })

    this.otherForm = this.fb.group({
      roomTypeId: ['', [Validators.required]],
      dateOfIstanbul: [''],
      isRelativesJoin: ['', [Validators.required]],
      relativeName: [''],
      proximityDegree: [''],
      isUsePermission: ['', [Validators.required]]
    })
  }

  checkPassportExpirationDate() {
    const passportExpiration = this.passportForm?.get('passportExpirationDate').value;

    if (passportExpiration) {
      const passportExpirationDate = new Date(passportExpiration);
      const formatpasportexpiration = formatDate(passportExpirationDate, 'dd-MM-yyyy', 'en-US');
      const currentDate = new Date(); //şimdiki zaman
      const formatcurrentDate = formatDate(currentDate, 'dd-MM-yyyy', 'en-US');

      const eightMonthsFromNow = new Date();
      eightMonthsFromNow.setMonth(currentDate.getMonth() + 6);
      eightMonthsFromNow.setDate(currentDate.getDate() - 1);
      const formateightmonthsfromnow = formatDate(eightMonthsFromNow, 'dd-MM-yyyy', 'en-US');

      if (currentDate >= passportExpirationDate) {
        this.passportForm.get('passportExpirationDate').setErrors({ expired: true });

      }
      else if (eightMonthsFromNow >= passportExpirationDate) {
        this.passportForm.get('passportExpirationDate').setErrors({ tooSoon: true });

      }

    }
  }


  createMainBuildObject(): any {
    const application: any = {
      fullName: this.personalInformationForm.get('fullName').value.toUpperCase(),
      identificationNumber: this.personalInformationForm.get('identificationNumber').value,
      genderId: this.personalInformationForm.get('genderId').value,
      birthday: this.personalInformationForm.get('birthday').value,
      birthPlace: this.personalInformationForm.get('birthPlace').value.toUpperCase(),
      phone: this.personalInformationForm.get('phone').value,
      email: this.personalInformationForm.get('email').value,
      maritalStatusId: this.personalInformationForm.get('maritalStatusId').value,

      adres: this.addressForm.get('adres').value.toUpperCase(),
      city: this.addressForm.get('city').value.toUpperCase(),
      no: this.addressForm.get('no').value.toUpperCase(),
      plz: this.addressForm.get('plz').value.toUpperCase(),

      passportNo: this.passportForm.get('passportNo').value.toUpperCase(),
      passportGivenDate: this.passportForm.get('passportGivenDate').value,
      passportExpirationDate: this.passportForm.get('passportExpirationDate').value,
      passportType: this.passportForm.get('passportType').value.toUpperCase(),

      roomTypeId: this.otherForm.get('roomTypeId').value,
      dateOfIstanbul: this.otherForm.get('dateOfIstanbul').value,
      isRelativesJoin: this.otherForm.get('isRelativesJoin').value,
      relativeName: this.otherForm.get('relativeName').value.toUpperCase(),
      proximityDegree: this.otherForm.get('proximityDegree').value.toUpperCase(),
      isUsePermission: this.otherForm.get('isUsePermission').value

    };
    return application;
  }



  saveForm() {

    this.isLoading = true;
    if (this.personalInformationForm.valid && this.addressForm.valid && this.otherForm.valid && this.passportForm.valid) {
      this.application = this.createMainBuildObject();

      this.hajjService.addHajjForm(this.application).subscribe((data: any) => {

        this.isLoading = false;
        Swal.fire({
          icon: 'success',
          title: 'Başarılı',
          text: 'Başvurunuz başarıyla gönderildi!',
          confirmButtonText: 'Kapat',

        })
          .then((success) => {
            // this.router.navigateByUrl('/hac-bilgi/' + this.hajjDetail)
            window.location.reload();
          });

      }, (error: any) => {

        this.isLoading = false;
        {
          // console.error('servisten sonra hata', error);
          Swal.fire({
            icon: 'error',
            title: 'Hata',
            text: error.error[0],
            confirmButtonText: 'Kapat'

          }
          )

        }
      }

      )
    }
    else {
      this.isLoading = false;
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Başvurunuz sırasında bir hata oluştu',
        confirmButtonText: 'Kapat'
      }
      )
    }
  }

}

