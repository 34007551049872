<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">

            <h2>{{'FUNERAL_FUND_APPLICATION'|translate}}</h2>
        </div>
    </div>
</div>



<div class="instructor-area pt-100 pb-70">
    <div class="container">

        <div class="container application  ">
            <!-- <div class="form-languages">
                <div class="language-item">
                    <div class="mydict">
                        <div>
                            <label class="label" *ngFor="let item of formLanguage">
                                <input type="radio" (change)="changeFormLang($event)" [value]="item.langcode"
                                    name="radio" [checked]="item.langcode === selectedFormLang">
                                <span>

                                    <img src="{{photoUrl+item.icon}}" alt="">

                                </span>
                            </label>
                        </div>
                    </div>

                    <div>


                    </div>
                </div>

            </div> -->

            <div class="tab-wrap application">
                <input type="radio" id="tab1" name="tabGroup1" class="tab" checked>
                <!-- <label for="tab1">{{'PERSONAL_INFORMATION'|translate}}</label> -->

                <!-- <input type="radio" id="tab2" name="tabGroup1" class="tab">
                <label for="tab2">{{'ADDRESS_INFORMATION'|translate}}</label> -->

                <div class="tab__content">
                    <form [formGroup]="mainForm">
                        <h3>{{'PERSONAL_INFORMATION'|translate}}</h3>
                        <fieldset class="row col md-12">
                            <div class="col-md-6">
                                <label class="form-label">{{'FULL_NAME'|translate}}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" formControlName="fullName"
                                    placeholder="{{'FULL_NAME'|translate}}">
                                <div *ngIf="mainForm.get('fullName')?.invalid && (mainForm.get('fullName').dirty || mainForm.get('fullName').touched)"
                                    class="alert">

                                    {{'THIS_FIELD_CANNOT_BE_LEFT_EMPTY'|translate}}

                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="birthDate" class="form-label">{{ 'BIRTHDAY' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="date" locale="en-US" class="form-control" id="birthDate"
                                    formControlName="birthDate" max="{{today | date:'yyyy-MM-dd'}}">

                                <div *ngIf="mainForm.get('birthDate').invalid && (mainForm.get('birthDate').dirty || mainForm.get('birthDate').touched)"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="birthPlace" class="form-label">{{ 'BIRTH_PLACE' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" id="birthPlace" formControlName="birthPlace"
                                    placeholder="{{'BIRTH_PLACE'|translate}}">
                                <div *ngIf="mainForm.get('birthPlace').invalid && (mainForm.get('birthPlace').dirty || mainForm.get('birthPlace').touched)"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="email" class="form-label">{{ 'EMAIL' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" id="email" formControlName="email"
                                    placeholder="{{ 'EMAIL' | translate }}">
                                <div *ngIf="mainForm.get('email')?.invalid && (mainForm.get('email').dirty || mainForm.get('email').touched)"
                                    class="alert">
                                    <div *ngIf="mainForm.get('email').errors?.required">
                                        {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                    </div>
                                    <div *ngIf="mainForm.get('email')?.errors?.email" class="alert">
                                        {{ 'PLEASE_ENTER_A_VALID_EMAIL' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{ 'GENDER' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <select formControlName="genderId" name="genderId" class="form-control">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let item of gender" [ngValue]="item.id">{{item.name|translate}}
                                    </option>
                                </select>
                                <div *ngIf="mainForm.get('genderId')?.invalid && (mainForm.get('genderId').dirty || mainForm.get('genderId').touched)"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>

                            </div>


                            <div class="col-md-6">
                                <label class="form-label">{{ 'MARITAL_STATUS' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <select formControlName="maritalStatusId" name="maritalStatusId" class="form-control">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let item of maritalStatus" [ngValue]="item.id">
                                        {{item.name|translate}}</option>
                                </select>
                                <div *ngIf="mainForm.get('maritalStatusId')?.invalid && (mainForm.get('maritalStatusId').dirty || mainForm.get('maritalStatusId').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{ 'CORRESPONDENCE_LANGUAGE' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <select formControlName="languageId" name="languageId" class="form-control">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let item of correspondenceLanguage" [ngValue]="item.id">
                                        {{item.name|translate}}</option>
                                </select>
                                <div *ngIf="mainForm.get('languageId')?.invalid && (mainForm.get('languageId').dirty || mainForm.get('languageId').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{ 'RESIDENCE_PERMIT' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <select formControlName="residencePermit" name="residencePermit" class="form-control">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let item of residencePermit" [value]="item?.key">
                                        {{item.key|translate}}</option>
                                </select>
                                <div *ngIf="mainForm.get('residencePermit')?.invalid && (mainForm.get('residencePermit').dirty || mainForm.get('residencePermit').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{ 'CITIZEN_SHIP' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <select formControlName="citizenshipId" name="citizenshipId" class="form-control">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let item of countries" [ngValue]="item.id">
                                        {{item.langcode}} {{item.name | translate}}</option>
                                </select>
                                <div *ngIf="mainForm.get('citizenshipId')?.invalid && (mainForm.get('citizenshipId').dirty || mainForm.get('citizenshipId').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>


                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="haveDualNationality" class="form-label">{{
                                            'IS_DUAL_NATIONALITY?' | translate
                                            }}<label class="text-danger fs-5">*</label>
                                        </label>


                                        <!-- <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch"
                                                formControlName="haveDualNationality" [(value)]="haveDualNationality"
                                                (change)="checkYes($event,'haveDualNationality')"
                                                id="flexSwitchCheckDefault">
                                            <label class="form-check-label" for="flexSwitchCheckDefault">{{
                                                haveDualNationality
                                                ? ('YES'|
                                                translate) :
                                                ('NO' | translate) }}</label>
                                        </div> -->

                                        <mat-radio-group aria-labelledby="example-radio-group-label"
                                            class="example-radio-group" formControlName="haveDualNationality"
                                            (change)="checkYes($event,'haveDualNationality')">
                                            <mat-radio-button class="example-radio-button"
                                                *ngFor="let option of checkBoxOption"
                                                [value]="option.value">{{option.name|translate}}</mat-radio-button>

                                        </mat-radio-group>

                                    </div>
                                    <div class="col-md-6">
                                        <label for="registeredAnotherFund" class="form-label">{{
                                            'DO_YOU_HAVE_A_MEMBERSHIP_TO_ANOTHER_FUND?' | translate
                                            }}<label class="text-danger fs-5">*</label>
                                        </label>


                                        <!-- <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch"
                                                formControlName="registeredAnotherFund" [(value)]="haveAnotherFund"
                                               (change)="checkYes($event,'haveAnotherFund')"
                                                id="flexSwitchCheckDefault">
                                            <label class="form-check-label" for="flexSwitchCheckDefault">{{
                                                haveAnotherFund
                                                ? ('YES'|
                                                translate) :
                                                ('NO' | translate) }}</label>
                                        </div> -->

                                        <mat-radio-group aria-labelledby="example-radio-group-label"
                                            class="example-radio-group" formControlName="registeredAnotherFund"
                                            (change)="checkYes($event,'haveAnotherFund')">
                                            <mat-radio-button class="example-radio-button"
                                                *ngFor="let option of checkBoxOption"
                                                [value]="option.value">{{option.name|translate}}</mat-radio-button>

                                        </mat-radio-group>

                                    </div>



                                </div>
                            </div>
                            <div class="col-md-6" [ngStyle]="{'display': haveDualNationality ? 'block' : 'none'}">
                                <label class="form-label">{{ 'DUAL_NATIONALITY_COUNTRY' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <select formControlName="dualNationalityCountryId" name="dualNationalityCountryId"
                                    class="form-control">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let item of countries" [ngValue]="item.id">
                                        {{item.langcode}} {{item.name|translate}}</option>
                                </select>
                                <div *ngIf="mainForm.get('dualNationalityCountryId')?.invalid && (mainForm.get('dualNationalityCountryId').dirty || mainForm.get('dualNationalityCountryId').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>

                            <div class="col-md-6" [ngStyle]="{'display': haveAnotherFund ? 'block' : 'none'}">
                                <label
                                    class="form-label">{{'UPLOAD_YOUR_CURRENT_FUND_MEMBERSHIP_CARD_OR_A_PHOTO_OF_YOUR_LAST_PAYMENT_TO_THE_FUND'|translate}}
                                </label>
                                <input class="form-control" type="file" id="formFile"
                                    (change)="uploadFileEvent($event)" accept="image/png, image/jpeg, image/jpg, application/pdf">
                                <div *ngIf="mainForm.get('fundPDFPath')?.invalid && (mainForm.get('fundPDFPath').dirty || mainForm.get('fundPDFPath').touched)"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>

                            </div>
                        </fieldset>
                    </form>



                    <form [formGroup]="switzerlandAddressForm">
                        <h3 class="form-title">{{'SWITZERLAND_ADDRESS'|translate}}</h3>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">{{'ADDRESS'|translate}}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" formControlName="adres"
                                    placeholder="{{'ADDRESS'|translate}}">
                                <div *ngIf="mainForm.get('adres')?.invalid && (mainForm.get('adres').dirty || mainForm.get('adres').touched)"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>

                            <div class="col-md-6">
                                <label class="form-label">{{'ADDRESS_NO'|translate}}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" formControlName="no"
                                    placeholder="{{'ADDRESS_NO'|translate}}">
                                <div *ngIf="mainForm.get('no')?.invalid && (mainForm.get('no').dirty || mainForm.get('no').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{'POST_CODE'|translate}}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" formControlName="postCode"
                                    placeholder="{{'POST_CODE'|translate}}">
                                <div *ngIf="mainForm.get('postCode')?.invalid && (mainForm.get('postCode').dirty || mainForm.get('postCode').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{'MOBILE_PHONE_NUMBER'|translate}}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" formControlName="personelPhoneNumber"
                                    placeholder="{{'MOBILE_PHONE_NUMBER'|translate}}">
                                <div *ngIf="mainForm.get('personelPhoneNumber')?.invalid && (mainForm.get('personelPhoneNumber').dirty || mainForm.get('phoneNumber').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{'PHONE_NUMBER'|translate}}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" formControlName="phoneNumber"
                                    placeholder="{{'PHONE_NUMBER'|translate}}">
                                <div *ngIf="mainForm.get('phoneNumber')?.invalid && (mainForm.get('phoneNumber').dirty || mainForm.get('phoneNumber').touched)"
                                    class="alert">
                                    <div *ngIf="mainForm.get('phoneNumber').errors?.required">
                                        {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </form>

                    <form [formGroup]="turkeyAddressForm">
                        <h3 class="form-title">{{'TURKEY_ADDRESS'|translate}}</h3>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">{{'ADDRESS'|translate}}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" formControlName="adres"
                                    placeholder="{{'ADDRESS'|translate}}">
                                <div *ngIf="mainForm.get('adres')?.invalid && (mainForm.get('adres').dirty || mainForm.get('adres').touched)"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{ 'CITY' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <select formControlName="cityId" name="city" class="form-control">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let item of cities" [ngValue]="item.id">
                                        {{item.name|translate}}</option>
                                </select>
                                <div *ngIf="mainForm.get('city')?.invalid && (mainForm.get('city').dirty || mainForm.get('city').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>


                        </div>

                    </form>
                    <div class="d-flex gap-2 mb-3">
                        <button type="button" class="btn btn-primary" (click)="spouseFormShow()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-plus" viewBox="0 0 16 16">
                                <path
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            {{'ADD_SPOUSE_INFORMATION'|translate}}
                        </button>
                    </div>
                    <div [ngStyle]="showSpouseForm==true? {'display':'block'} :  {'display':'none'}">
                        <h4>{{'SPOUSE_INFORMATION'|translate}}</h4>
                        <form [formGroup]="spouseForm" class="row">
                            <div class="col-md-6">
                                <label class="form-label">{{'FULL_NAME'|translate}}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" formControlName="fullName"
                                    placeholder="{{'FULL_NAME'|translate}}">
                                <div *ngIf="spouseForm.get('fullName')?.invalid && (spouseForm.get('fullName').dirty || spouseForm.get('fullName').touched)"
                                    class="alert">

                                    {{'THIS_FIELD_CANNOT_BE_LEFT_EMPTY'|translate}}

                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="birthDate" class="form-label">{{ 'BIRTHDAY' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="date" class="form-control" id="birthDate" formControlName="birthDate" max="{{today | date:'yyyy-MM-dd'}}">
                                <div *ngIf="spouseForm.get('birthDate').invalid && (spouseForm.get('birthDate').dirty || spouseForm.get('birthDate').touched)"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="birthPlace" class="form-label">{{ 'BIRTH_PLACE' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" id="birthPlace" formControlName="birthPlace"
                                    placeholder="{{ 'BIRTH_PLACE' | translate }}">
                                <div *ngIf="spouseForm.get('birthPlace').invalid && (spouseForm.get('birthPlace').dirty || spouseForm.get('birthPlace').touched)"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{ 'GENDER' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <select formControlName="genderId" name="genderId" class="form-control">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let item of gender" [ngValue]="item.id">{{item.name|translate}}
                                    </option>
                                </select>
                                <div *ngIf="spouseForm.get('genderId')?.invalid && (spouseForm.get('genderId').dirty || spouseForm.get('genderId').touched)"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{ 'RESIDENCE_PERMIT' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <select formControlName="residencePermit" name="residencePermit" class="form-control">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let item of residencePermit" [value]="item?.key">
                                        {{item.key|translate}}</option>
                                </select>
                                <div *ngIf="spouseForm.get('residencePermit')?.invalid && (spouseForm.get('residencePermit').dirty || spouseForm.get('residencePermit').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{ 'CITIZEN_SHIP' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <select formControlName="citizenshipId" name="citizenshipId" class="form-control">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let item of countries" [ngValue]="item.id">
                                        {{item.langcode}} {{item.name | translate}}</option>
                                </select>
                                <div *ngIf="spouseForm.get('citizenshipId')?.invalid && (spouseForm.get('citizenshipId').dirty || spouseForm.get('citizenshipId').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>

                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="haveDualNationality" class="form-label">{{
                                            'IS_DUAL_NATIONALITY?' | translate
                                            }}<label class="text-danger fs-5">*</label>
                                        </label>

                                        <!-- <div class="col-md-6">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch"
                                                    formControlName="haveDualNationality"
                                                    [(value)]="haveSpouseDualNationality"
                                                    (change)="checkYes($event,'haveSpouseDualNationality')"
                                                    id="flexSwitchCheckDefault">
                                                <label class="form-check-label" for="flexSwitchCheckDefault">{{
                                                    haveSpouseDualNationality
                                                    ? ('YES'|
                                                    translate) :
                                                    ('NO' | translate) }}</label>
                                            </div>
                                        </div> -->

                                        <mat-radio-group aria-labelledby="example-radio-group-label"
                                            class="example-radio-group" formControlName="haveDualNationality"
                                            (change)="checkYes($event,'haveSpouseDualNationality')">
                                            <mat-radio-button class="example-radio-button"
                                                *ngFor="let option of checkBoxOption"
                                                [value]="option.value">{{option.name|translate}}</mat-radio-button>

                                        </mat-radio-group>

                                    </div>

                                    <div class="col-md-6"
                                        [ngStyle]="{'display': haveSpouseDualNationality ? 'block' : 'none'}">
                                        <label class="form-label">{{ 'DUAL_NATIONALITY_COUNTRY' | translate }}<label
                                                class="text-danger fs-5">*</label></label>
                                        <select formControlName="dualNationalityCountryId"
                                            name="dualNationalityCountryId" class="form-control">
                                            <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                            <option *ngFor="let item of countries" [ngValue]="item.id">
                                                {{item.langcode}} {{item.name | translate}}</option>
                                        </select>
                                        <div *ngIf="spouseForm.get('dualNationalityCountryId')?.invalid && (mainForm.get('dualNationalityCountryId').dirty || mainForm.get('dualNationalityCountryId').touched)"
                                            class="alert">

                                            {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                        </div>

                                    </div>
                                </div>
                                <!-- <div class="col-md-6">
                                        <label class="form-label">{{ 'RESIDENCE_PERMIT' | translate }}<label
                                                class="text-danger fs-5">*</label></label>
                                        <select formControlName="residencePermit" name="residencePermit" class="form-control">
                                            <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                            <option *ngFor="let item of residencePermit" [ngValue]="item.name">
                                                {{item.name|translate}}</option>
                                        </select>
                                        <div *ngIf="mainForm.get('residencePermit')?.invalid && (mainForm.get('residencePermit').dirty || mainForm.get('residencePermit').touched)"
                                            class="alert">
        
                                            {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
        
                                        </div>
        
                                    </div> -->


                            </div>



                        </form>
                    </div>

                    <div class="d-flex gap-2 mb-3">
                        <div style="display: flex;">
                            <button type="button" class="btn btn-primary" (click)="showFamilyMemberForm()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-plus" viewBox="0 0 16 16">
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                                {{'ADD_CHILD_INFORMATION'|translate}}
                            </button>
                            <div style="margin-top: 36px; margin-left:10px">
                               ({{'CHILDREN_UNDER_EIGHTEEN'|translate}}) 
                            </div>
                        </div>

                    </div>

                    <div [ngStyle]="showMemberForm==true? {'display':'block'} :  {'display':'none'} ">
                        <form [formGroup]="memberForm">
                            <div class="col-lg-12 col-md-12 ">
                                <h3 class="form-title">{{'CHILD_INFORMATION'|translate}}</h3>
                            </div>
                            <div class="row" formArrayName="familyMembers">
                                <div *ngFor="let item of familyMembers.controls; let i=index">
                                    <div class="row" [formGroupName]="i">
                                        <h3>{{i+1+'.'}}</h3>
                                        <div class="col-md-6">
                                            <label for="fullName" class="form-label">{{'FULL_NAME'|translate}}<label
                                                    class="text-danger fs-5">*</label></label>
                                            <input type="text" class="form-control" formControlName="fullName"
                                                placeholder="{{'FULL_NAME'|translate}}">

                                        </div>
                                        <div class="col-md-6">
                                            <label for="birthDate" class="form-label">{{ 'BIRTHDAY' | translate }}<label
                                                    class="text-danger fs-5">*</label></label>
                                            <input type="date" class="form-control" id="birthDate" max="{{today | date:'yyyy-MM-dd'}}"
                                                formControlName="birthDate">

                                        </div>
                                        <div class="col-md-6">
                                            <label for="birthPlace" class="form-label">{{ 'BIRTH_PLACE' | translate
                                                }}<label class="text-danger fs-5">*</label></label>
                                            <input type="text" class="form-control" id="birthPlace" placeholder="{{ 'BIRTH_PLACE' | translate
                                        }}" formControlName="birthPlace">

                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">{{ 'GENDER' | translate }}<label
                                                    class="text-danger fs-5">*</label></label>
                                            <select formControlName="genderId" name="genderId" class="form-control">
                                                <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                                <option *ngFor="let item of gender" [ngValue]="item.id">
                                                    {{item.name|translate}}
                                                </option>
                                            </select>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="d-flex gap-2 mb-3">
                                    <button type="button" class="btn btn-primary" (click)="addNewFamilyMember()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                            <path
                                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                        </svg>
                                        {{'ADD_CHILD'|translate}}
                                    </button>
                                    <button type="button" class="btn btn-primary" (click)="deleteFamilyMember()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                            <path
                                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                        </svg>
                                        {{'DELETE_CHILD'|translate}}
                                    </button>
                                </div>

                            </div>
                        </form>

                    </div>
                    <section class="example-section">
                        <mat-checkbox class="example-margin" (change)="checkYes($event,'regulation')"><a
                                [href]="photoUrl+regulation" target="_blank"><u
                                    class="text-danger">{{'REGULATION'|translate}}</u>&nbsp;</a>{{'I_HAVE_READ_AND_ACCEPT'|translate}}
                            <label class="text-danger fs-5">*</label></mat-checkbox>
                    </section>

                    <button
                        [disabled]="isLoading || !mainForm.valid || !switzerlandAddressForm.valid|| !turkeyAddressForm.valid || !isRegulation"
                        (click)="saveForm()" class="btn btn-primary col-md-12">
                        <span *ngIf="!isLoading">{{'SUBMIT'|translate}}</span>
                        <span *ngIf="isLoading">
                            <i class="fa fa-spinner fa-spin"></i> {{'SENDING'|translate}}
                        </span>
                        <div *ngIf="!isLoading" routerLink=""></div>
                    </button>
                </div>
            </div>

        </div>


    </div>
</div>