import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventApplication } from 'src/app/models/eventApplication';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventApplicationService {
  path = environment.apiUrl;

  constructor(private http: HttpClient) {}

  addEventApplication(data:EventApplication): Observable<EventApplication> {
    const url = `EventForm/Add`;
    return this.http.post<EventApplication>(this.path + url,data)

  }

}
