<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">

            <h2>{{'HAJJ_APPLICATION'|translate}}</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-70 pb-70">
    <div class="container">

        <div class="container application">

            <!-- <h3 >Hac Başvurusu</h3> -->
            <div class="tab-wrap application">

                <input type="radio" id="tab1" name="tabGroup1" class="tab" checked>
                <label for="tab1">{{'PERSONAL_INFORMATION'|translate}}</label>

                <input type="radio" id="tab2" name="tabGroup1" class="tab"
                    [disabled]="!this.checkForm(personalInformationForm)">
                <label for="tab2">{{'ADDRESS_INFORMATION'|translate}}</label>

                <input type="radio" id="tab3" name="tabGroup1" class="tab" [disabled]="!this.checkForm(addressForm)">
                <label for="tab3">{{'PASSPORT_INFORMATION'|translate}}</label>

                <input type="radio" id="tab4" name="tabGroup1" class="tab" [disabled]="!this.checkForm(passportForm)">
                <label for="tab4">{{'ADDITIONAL_INFORMATION'|translate}}</label>

                <div class="tab__content personal">
                    <h3>{{'PERSONAL_INFORMATION'|translate}}</h3>
                    <form class="row col md-12" [formGroup]="personalInformationForm">

                        <div class="col-md-6">
                            <label for="fullName" class="form-label">{{ 'FULL_NAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="fullName"
                                placeholder="{{ 'FULL_NAME' | translate }}" required>
                            <div *ngIf="personalInformationForm.get('fullName')?.invalid && (personalInformationForm.get('fullName')?.dirty || personalInformationForm.get('fullName')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">{{ 'TC_IDENTIFICATION_NUMBER' |translate}}</label>
                            <input type="text" class="form-control" formControlName="identificationNumber"
                                placeholder="xxxxxxxxxxx" maxlength="11">
                            <div *ngIf="personalInformationForm.get('identificationNumber')?.invalid && (personalInformationForm.get('identificationNumber').dirty || personalInformationForm.get('identificationNumber').touched)"
                                class="alert">
                                <div
                                    *ngIf=" personalInformationForm.get('identificationNumber')?.hasError('minlength') ">
                                    {{'PLEASE_ENTER_11_DIGITS'|translate}}
                                </div>

                            </div>

                        </div>
                        <div class="col-md-6">
                            <label class="form-label">{{ 'MARITAL_STATUS' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <select formControlName="maritalStatusId" name="maritalStatusId" class="form-control">
                                <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                <option *ngFor="let item of maritalStatus" [ngValue]="item.id">
                                    {{item.name|translate}}</option>
                            </select>
                            <div *ngIf="personalInformationForm.get('maritalStatusId')?.invalid && (personalInformationForm.get('maritalStatusId').dirty || personalInformationForm.get('maritalStatusId').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>

                        </div>
                        <div class="col-md-6">
                            <label for="genderId" class="form-label">{{ 'GENDER' | translate }}<label class="text-danger fs-5">*</label></label>
                            <select id="genderId" class="form-select" formControlName="genderId">
                                <option value="" disabled selected>{{ 'SELECT' | translate }}</option>
                                <option *ngFor="let item of gender" [ngValue]="item.id">{{ item.name | translate}}
                                </option>
                            </select>
                            <div *ngIf="personalInformationForm.get('genderId')?.invalid && (personalInformationForm.get('genderId')?.dirty || personalInformationForm.get('genderId')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>

                        </div>
                        <div class="col-md-6">
                            <label for="birthday" class="form-label">{{ 'BIRTHDAY' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="date" class="form-control" id="birthday" formControlName="birthday">
                            <div *ngIf="personalInformationForm.get('birthday').invalid && (personalInformationForm.get('birthday').dirty || personalInformationForm.get('birthday').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="birthPlace" class="form-label">{{ 'BIRTH_PLACE' | translate }}</label>
                            <input type="text" class="form-control" id="birthPlace" formControlName="birthPlace"
                                placeholder="{{ 'BIRTH_PLACE' | translate }}">
                            <div *ngIf="personalInformationForm.get('birthPlace').invalid && (personalInformationForm.get('birthPlace').dirty || personalInformationForm.get('birthPlace').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <label for="motherName" class="form-label">{{'MOTHER_NAME'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="motherName" formControlName="motherName"
                                placeholder="{{'MOTHER_NAME'|translate}}">
                            <div *ngIf="personalInformationForm.get('motherName').invalid && (personalInformationForm.get('motherName').dirty || personalInformationForm.get('motherName').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="fatherName" class="form-label">{{'FATHER_NAME'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="fatherName" formControlName="fatherName"
                                placeholder="{{'FATHER_NAME'|translate}}">
                            <div *ngIf="personalInformationForm.get('fatherName').invalid && (personalInformationForm.get('fatherName').dirty || personalInformationForm.get('fatherName').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div> -->
                        <div class="col-md-6">
                            <label for="phone" class="form-label">{{ 'PHONE_NUMBER' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="phone" formControlName="phone"
                                placeholder="{{ 'PHONE_NUMBER' | translate }}">
                            <div *ngIf="personalInformationForm.get('phone').invalid && (personalInformationForm.get('phone').dirty || personalInformationForm.get('phone').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="email" class="form-label">{{ 'EMAIL' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="email" class="form-control" id="email" formControlName="email"
                                placeholder="{{ 'EMAIL' | translate }}">
                            <div *ngIf="personalInformationForm.get('email')?.invalid && (personalInformationForm.get('email').dirty || personalInformationForm.get('email').touched)"
                                class="alert">
                                <div *ngIf="personalInformationForm.get('email').errors?.required">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>
                                <div *ngIf="personalInformationForm.get('email')?.errors?.email" class="alert">
                                    {{ 'PLEASE_ENTER_A_VALID_EMAIL' | translate }}
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" (click)="nextTab(0)"
                            [disabled]="!this.checkForm(personalInformationForm)">
                            <div *ngIf="!this.checkForm(personalInformationForm)"><label class=" fs-5">*</label>{{
                                'FILL_IN_ALL_FIELDS_TO_PROCEED' | translate
                                }}</div>
                            <div *ngIf="this.checkForm(personalInformationForm)"> {{ 'NEXT' | translate }}</div>
                        </button>
                    </form>

                </div>

                <div class="tab__content">
                    <h3>{{'SWITZERLAND_ADDRESS_INFORMATION'|translate}}</h3>
                    <form class="row col md-12" [formGroup]="addressForm">
                        <div class="col-md-12">
                            <label for="adres" class="form-label">{{ 'ADDRESS' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <textarea class="form-control" id="addres" rows="3" formControlName="adres"
                                placeholder="{{ 'ADDRESS' | translate }}"></textarea>
                            <div *ngIf="addressForm.get('adres').invalid && (addressForm.get('adres').dirty || addressForm.get('adres').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-4">
                            <label for="no" class="form-label">{{ 'ADDRESS_NO' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="no" formControlName="no"
                                placeholder="{{ 'ADDRESS_NO' | translate }}">
                            <div *ngIf="addressForm.get('no').invalid && (addressForm.get('no').dirty || addressForm.get('no').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-4">
                            <label for="city" class="form-label">{{ 'CITY' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="city" formControlName="city"
                                placeholder="{{ 'CITY' | translate }}">
                            <div *ngIf="addressForm.get('city').invalid && (addressForm.get('city').dirty || addressForm.get('city').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="plz" class="form-label">{{ 'PLZ' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="plz" formControlName="plz"
                                placeholder="{{ 'PLZ' | translate }}">
                            <div *ngIf="addressForm.get('plz').invalid && (addressForm.get('plz').dirty || addressForm.get('plz').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>


                        <button type="submit" class="btn btn-primary" (click)="nextTab(1)"
                            [disabled]="!this.checkForm(addressForm)">
                            <div *ngIf="!this.checkForm(addressForm)">{{ 'FILL_IN_ALL_FIELDS_TO_PROCEED' | translate
                                }}</div>
                            <div *ngIf="this.checkForm(addressForm)"> {{ 'NEXT' | translate }}</div>
                        </button>
                    </form>
                </div>

                <div class="tab__content">
                    <h3>{{'PASSPORT_INFORMATION'|translate}}</h3>
                    <form class="row col md-12" [formGroup]="passportForm">
                        <div class="col-md-6">
                            <label for="passportType" class="form-label">{{ 'PASSPORT_TYPE' | translate
                                }}<label class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="passportType"
                                placeholder="{{ 'PASSPORT_TYPE' | translate }}" formControlName="passportType">
                            <div *ngIf="passportForm.get('passportType').invalid && (passportForm.get('passportType').dirty || passportForm.get('passportType').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="passportNo" class="form-label">{{ 'PASSPORT_NUMBER' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="passportNo" formControlName="passportNo"
                                placeholder="{{ 'PASSPORT_NUMBER' | translate }}"
                                style="text-transform: uppercase !important;">
                            <div *ngIf="passportForm.get('passportNo').invalid && (passportForm.get('passportNo').dirty || passportForm.get('passportNo').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="passportGivenDate" class="form-label">{{ 'PASSPORT_GIVEN_DATE' | translate
                                }}<label class="text-danger fs-5">*</label> <label class="alert"> ( gg.aa.yyyy
                                    )</label></label>
                            <input type="date" class="form-control" id="passportGivenDate"
                                placeholder="{{ 'PASSPORT_GIVEN_DATE' | translate }}"
                                formControlName="passportGivenDate">
                            <div *ngIf="passportForm.get('passportGivenDate').invalid && (passportForm.get('passportGivenDate').dirty || passportForm.get('passportGivenDate').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="passportExpirationDate" class="form-label">{{ 'PASSPORT_EXPIRATION_DATE' |
                                translate }}<label class="text-danger fs-5">*</label> <label class="alert"> ( gg.aa.yyyy
                                    )</label></label>
                            <input type="date" class="form-control" id="passportExpirationDate"
                                placeholder="{{ 'PASSPORT_EXPIRATION_DATE' | translate }}"
                                formControlName="passportExpirationDate" (change)="checkPassportExpirationDate()">
                            <div
                                *ngIf="passportForm.get('passportExpirationDate').invalid && 
                            (passportForm.get('passportExpirationDate').dirty || passportForm.get('passportExpirationDate').touched)">
                                <div *ngIf="passportForm.get('passportExpirationDate').hasError('required')"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>
                                <div *ngIf="passportForm.get('passportExpirationDate').hasError('expired')"
                                    class="alert">
                                    {{ 'PASSPORT_EXPIRED' | translate }}
                                </div>
                                <div *ngIf="passportForm.get('passportExpirationDate').hasError('tooSoon')"
                                    class="alert">
                                    {{ 'PASSPORT_DURATION_SHOULD_NOT_BE_LESS_6_MONTHS' | translate }}
                                </div>

                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary" (click)="nextTab(2)"
                            [disabled]="!this.checkForm(passportForm)">
                            <div *ngIf="!this.checkForm(passportForm)">{{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate
                                }}</div>
                            <div *ngIf="this.checkForm(passportForm)"> {{ 'NEXT' | translate }}</div>
                        </button>
                    </form>
                </div>

                <div class="tab__content">
                    <h3>{{'ADDITIONAL_INFORMATION'|translate}}</h3>
                    <form class="row col-md-12" [formGroup]="otherForm">
                        <div class="col-md-6">
                            <label for="roomType" class="form-label">{{'ROOM_TYPE'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <select id="roomType" formControlName="roomTypeId" class="form-select">
                                <option value="" selected disabled>{{'SELECT'|translate}}</option>
                                <option *ngFor="let item of roomTypes" [ngValue]="item.id">{{item.roomTypes}}
                                    {{'FOR_PERSON'|translate}}
                                </option>
                            </select>
                            <div *ngIf="otherForm.get('roomTypeId')?.invalid && (otherForm.get('roomTypeId').dirty || otherForm.get('roomTypeId').touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>


                        </div>

                        <div class="row">

                            <div class="col-md-6">
                                <div class="row">
                                    <label for="isUsePermission" class="form-label">{{
                                        'DO_YOU_WANT_TO_TAKE_LEAVE_IN_TURKEY_ON_YOUR_RETURN_FROM_HAJJ?' | translate
                                        }}<label class="text-danger fs-5">*</label></label>
                                </div>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    formControlName="isUsePermission" (change)="checkYes($event,'isUsePermission')">
                                    <mat-radio-button class="example-radio-button" *ngFor="let option of checkBoxOption"
                                        [value]="option.value">{{option.name|translate}}</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div class="col-md-6" [ngStyle]="{'display': isUsePermission ? 'block' : 'none'}">
                                <label for="dateOfIstanbul" class="form-label">{{ 'YOUR_RETURN_DATE_FROM_ISTANBUL' |
                                    translate
                                    }}<label class="text-danger fs-5">*</label> <label class="alert"> ( gg.aa.yyyy
                                        )</label></label>
                                <input (change)="changeValue($event,'dateOfIstanbul')" type="date" class="form-control"
                                    id="dateOfIstanbul" placeholder="{{ 'YOUR_RETURN_DATE_FROM_ISTANBUL' | translate }}"
                                    formControlName="dateOfIstanbul">
                                <div *ngIf="otherForm.get('dateOfIstanbul').invalid && (otherForm.get('dateOfIstanbul').dirty || otherForm.get('dateOfIstanbul').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-6">
                                <div class="row">
                                    <label for="isRelativesJoin" class="form-label">{{
                                        'DO_YOU_HAVE_A_FIRST-DEGREE_RELATIVE_YOU_WOULD_LIKE_TO_TAKE_FROM_TURKEY?' |
                                        translate
                                        }}<label class="text-danger fs-5">*</label></label>
                                </div>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    formControlName="isRelativesJoin" (change)="checkYes($event,'isRelativesJoin')">
                                    <mat-radio-button class="example-radio-button" *ngFor="let option of checkBoxOption"
                                        [value]="option.value">{{option.name|translate}}</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div class="col-md-6" [ngStyle]="{'display': isRelativesJoin ? 'block' : 'none'}">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="relativeName" class="form-label">{{ 'FULL_NAME' | translate }}</label>
                                        <input type="text" class="form-control" formControlName="relativeName"
                                            (change)="changeValue($event,'relativeName')"
                                            placeholder="{{ 'FULL_NAME' | translate }}">
                                        <div *ngIf="personalInformationForm.get('relativeName')?.invalid && (personalInformationForm.get('relativeName')?.dirty || personalInformationForm.get('relativeName')?.touched)"
                                            class="alert">
                                            {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="proximityDegree" class="form-label">{{ 'THE_DEGREE_OF_PROXIMITY' |
                                            translate }}<label class="text-danger fs-5">*</label></label>
                                        <input type="text" class="form-control" formControlName="proximityDegree"
                                            (change)="changeValue($event,'proximityDegree')"
                                            placeholder="{{ 'THE_DEGREE_OF_PROXIMITY' | translate }}">
                                        <div *ngIf="personalInformationForm.get('proximityDegree')?.invalid && (personalInformationForm.get('proximityDegree')?.dirty || personalInformationForm.get('proximityDegree')?.touched)"
                                            class="alert">
                                            {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section class="example-section">
                            <mat-checkbox class="example-margin" (change)="checkYes($event,'isRegulation')"><a [href]="photoUrl+regulation" target="_blank"
                                    href=""><u
                                        class="text-danger">{{'REGULATION'|translate}}</u></a>{{'I_HAVE_READ_AND_ACCEPT'|translate}}
                                <label class="text-danger fs-5">*</label></mat-checkbox>
                        </section>
                        <button
                            [disabled]="isLoading || !personalInformationForm.valid || !addressForm.valid || !passportForm.valid || !otherForm.valid || !isRegulation"
                            (click)="saveForm() " class="btn btn-primary">
                            <span *ngIf="!isLoading">{{'SUBMIT'|translate}}</span>
                            <span *ngIf="isLoading">
                                <i class="fa fa-spinner fa-spin"></i> {{'SENDING'|translate}}
                            </span>
                            <!-- <div *ngIf="!isLoading" routerLink=""></div> -->
                        </button>

                    </form>


                </div>

            </div>

        </div>
    </div>
</div>