import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { services } from 'src/app/services/services.service';
import { environment } from 'src/environments/environment';
@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
	news: any[] = [];
	photoUrl: string = environment.imgUrl
	constructor(private services: services) { }

	ngOnInit(): void {
		this.services.getFrontAllAnnouncements().subscribe((data: any) => {
			this.news = data.filter(news => news.isNews == true && news.isDeleted == false && news.isActive == true).sort((a, b) => {
				return a.order - b.order;
			});

		});
	}

	blogSlides: OwlOptions = {
		loop: false,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: false,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			768: {
				items: 2
			},
			1200: {
				items: 3
			}
		}
	}



}