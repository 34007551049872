
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class PageService {
    path = environment.apiUrl;
    languageCode: string = '';

    constructor(
        private http: HttpClient) {
        this.languageCode = localStorage.getItem('language');
    }

    getContentPage(id): Observable<any> {
        const url = this.path + `GetContentPageById?Id=${id}`;
        return this.http.get(url);

    }

    getAllFrontPages(): Observable<any> {
        const url = this.path + `FrontPage/GetList/${this.languageCode}`;
        return this.http.get(url);

    }

    getAllFrontPageRecord(): Observable<any> {
        const url = this.path + `FrontPageRecord/GetList`;
        return this.http.get(url);

    }

    getFrontPageByCode(code: string): Observable<any> {
        const url = this.path + `FrontPage/Get/${code}`;
        return this.http.get(url);

    }

    getFrontPageRecordByCode(code: string): Observable<any> {
        const url = this.path + `FrontPageRecord/Get/${code}`;
        return this.http.get(url);

    }
}