import { Directive, ElementRef, Renderer2, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[appCurrencyInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyInputDirective),
      multi: true
    }
  ]
})
export class CurrencyInputDirective implements ControlValueAccessor {

  private decimalSeparator = ',';

  constructor(private el: ElementRef, private renderer: Renderer2) { }


  private onChange: (value: string) => void;
  private onTouched: () => void;


  writeValue(value: any): void {
    if (value !== undefined) {
      const formattedValue = this.formatCurrency(value.toString());
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    }
  }


  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }


  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }


  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }


  @HostListener('input', ['$event.target.value']) onInput(value: string) {
    value = this.preventLeadingZero(value);
    const sanitizedValue = this.sanitizeValue(value);
    const formattedValue = this.formatCurrency(sanitizedValue);
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);

    // if (this.onChange) {

    //   this.onChange(sanitizedValue.replace(/[^0-9]/g, ''));
    // }
  }


  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key === '.' || event.key === ',') {
      event.preventDefault();
      const currentValue = this.el.nativeElement.value;
      if (!currentValue.includes(this.decimalSeparator)) {
        this.renderer.setProperty(this.el.nativeElement, 'value', currentValue + this.decimalSeparator);
      }
    }
  }


  @HostListener('blur') onBlur() {
    let value = this.el.nativeElement.value;
  
    if (value.trim() === '') {
      return;
    }
  
    if (!value.includes(this.decimalSeparator)) {
      value += this.decimalSeparator + '00';
    } else {
      const [integerPart, decimalPart] = value.split(this.decimalSeparator);
      const formattedDecimal = (decimalPart || '').slice(0, 2).padEnd(2, '0');
      value = `${integerPart}${this.decimalSeparator}${formattedDecimal}`;
    }
  
    if (value.endsWith(this.decimalSeparator)) {
      value = value.slice(0, -1);
    }
  
    this.renderer.setProperty(this.el.nativeElement, 'value', value);
  
    if (this.onChange) {
      // Remove the replace call to keep the formatting
      this.onChange(value); 
    }
  
    if (this.onTouched) {
      this.onTouched();
    }
  }


  private preventLeadingZero(value: string): string {
    if (value.startsWith('0') && !value.startsWith('0' + this.decimalSeparator)) {
      return value.replace(/^0+/, '');
    }
    return value;
  }


  private sanitizeValue(value: string): string {
    return value.replace(/[^\d,]/g, '').replace(/\./g, this.decimalSeparator).replace(/,/g, this.decimalSeparator);
  }


  private formatCurrency(value: string): string {
    const [integerPart, decimalPart] = value.split(this.decimalSeparator);
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const formattedDecimal = (decimalPart || '').slice(0, 2);
    return formattedDecimal
      ? `${formattedInteger}${this.decimalSeparator}${formattedDecimal}`
      : formattedInteger;
  }
}
