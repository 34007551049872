import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { services } from 'src/app/services/services.service';
import { CommunicationService } from '../../../services/communication.service';
import Swal from 'sweetalert2';
import { CommunicationForm } from 'src/app/models/communicationForm';
import { AppSettingService } from '../../../services/appsetting.service';
@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    form!: FormGroup;

    address: any ;
    fax: any;
    telephone: any;
    facebook: any;
    twitter: any;
    instagram: any;
    youtube: any;
    hour: any;
    mail: any;
  
    footerArea: any;
    constructor(private appSettingService: AppSettingService, private communicationService: CommunicationService, private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.getAppSettings();
        this.communicationForm();
    }


    getAppSettings() {
      this.appSettingService.getAppSetting().subscribe((data: any) => {
        this.address = data.find(item => item.key === "adres")?.value;
        this.telephone = data.find(item => item.key === "telefon")?.value;
        this.facebook = data.find(item => item.key === "facebook")?.value;
        this.twitter = data.find(item => item.key === "twitter")?.value;
        this.instagram = data.find(item => item.key === "instagram")?.value;
        this.youtube = data.find(item => item.key === "youtube")?.value;
        this.hour = data.find(item => item.key === "calismasaati")?.value;
        this.mail = data.find(item => item.key === "mail")?.value;
      })
    }
  


    //İletişim Form
    communicationForm() {
        this.form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            mail: ['', Validators.compose([Validators.required, Validators.email])],
            title: ['', Validators.required],
            message: ['', Validators.required],
            isContactRequest:true
            // phone: ['', Validators.required]
        })
    }

    submitForm() {
        if (this.form.valid) {
            const communicationForm: CommunicationForm = Object.assign(this.form.value);
            Swal.fire({
                title: 'Talebiniz gönderilsin mi?',
                showDenyButton: true,
                confirmButtonText: 'Evet', confirmButtonColor: "#238dc1",
                denyButtonText: `Hayır`, denyButtonColor: "#b47f00",
            }).then((response) => {
                if (response.isConfirmed) {
                    this.communicationService.contactSend(communicationForm).subscribe((data: any) => {
                        if (data) {
                            Swal.fire({
                                title: 'Başarılı',
                                text: 'İletişim talebiniz başarıyla gönderilmiştir.',
                                icon: 'success', iconColor: "#89dc65",
                                confirmButtonText: 'Tamam', confirmButtonColor: "#89dc65",
                            })
                        }
                    });
                }
            });
        } else {
            Swal.fire({
                title: 'İletişim bilgilerini doldurun',
                icon: 'warning', iconColor: "#d4c201",
                confirmButtonText: 'Tamam', confirmButtonColor: "#89dc65",
            })
        }
    }


}