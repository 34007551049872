import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Content } from 'src/app/models/content';
import { EventApplication } from 'src/app/models/eventApplication';
import { MaritalStatus } from 'src/app/models/maritalStatus';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UmrahService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) {}


    addUmreForm(data: any): Observable<any> {
        const url = `UmreForm/UmrahRegistrationForm`;
        return this.http.post(this.path + url, data);
    }

    getUmrahPeriods(): Observable<any> {
        const url = `Period/GetListUP`;
        return this.http.get(this.path + url);
    }
    
}