import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class ScholarshipService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) { }

    addBursaryForm(data: any): Observable<any> {
        const url = `BursaryForm/Add`;
        return this.http.post(this.path + url, data);
    }

    getBursaryTypes(): Observable<any> {
        const url = `BursaryType/GetList`;
        return this.http.get(this.path + url);
    }

    getBursaryPeriod(): Observable<any> {
        const url = `BursaryPeriod/GetList`;
        return this.http.get(this.path + url);
    }
}
