import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnlineDonate } from './../models/onlineDonate';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OnlineDonateService {
    path: string = environment.apiUrl

    constructor(private http: HttpClient) { }

    createPayment(donate: OnlineDonate): Observable<OnlineDonate> {
        const url = this.path + "Stripe/CreatePayment";
        return this.http.post<OnlineDonate>(url, donate);
    }
}