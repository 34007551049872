
import { Component, HostListener, OnInit } from '@angular/core';
import { Language } from 'src/app/models/language';
import { LanguageService } from 'src/app/services/language.service';
import { MenuService } from 'src/app/services/menu.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header-style-six',
    templateUrl: './header-style-six.component.html',
    styleUrls: ['./header-style-six.component.scss']
})
export class HeaderStyleSixComponent implements OnInit {
    photoUrl: string = environment.imgUrl;
    isSticky: boolean = false;
    isLoading: boolean = true;
    language: Language;
    languages: Language[] = [];
    topmenus: any;

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(private translationService: TranslationService, private languageService: LanguageService, private menuService: MenuService) { }

    ngOnInit() {
        this.getAllLanguages();

        this.detectHeader()
        // this.getCountry();
        this.getAllFrontMenus();
      



    }

    getAllLanguages() {
        this.languageService.getAllLanguage().subscribe((data: any) => {
            this.languages = data.filter(language => language.isActive === true);
            this.setDefaultLanguage();

        })
    }

    setDefaultLanguage() {
        const lang = this.translationService.getSelectedLanguage();

        if (lang)
            this.setLanguageWithRefresh(lang);
    }

    setLanguageWithRefresh(lang: string) {
        this.languages.forEach((language: Language) => {
            if (language.langcode === lang) {
                language.isActive = true;
                this.language = language;
            } else {
                language.isActive = false;
            }
        });

        this.translationService.setLanguage(lang);
    }

    setNewLanguageRefresh(lang: string) {
        this.translationService.setLanguage(lang).subscribe((response) => {
            this.setLanguageWithRefresh(response['LANGUAGE']);
            window.location.reload();
        });

    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }


    getAllFrontMenus() {

        this.menuService.getAllFrontMenuItem().subscribe((data: any) => {
            this.topmenus = data.data.filter(x => x.parentId === null && x.isActive == true).sort((a, b) => a.order - b.order);
            this.isLoading = false;
        })
    }



    windowScrolled: boolean | undefined;

    @HostListener("window:scroll", [])
    onWindowScroll() {
        this.windowScrolled = window.scrollY > 100;
    }
    // Navigation
    navMethod: boolean = false;
    toggleNav() {
        this.navMethod = !this.navMethod;
    }

    // Mobile 
    open: boolean = false;
    trigger(item: { open: boolean; }) {
        item.open = !item.open;
    }
    // Add class on resize and onload window
    visible: boolean = false;
    breakpoint: number = 991;
    public innerWidth: any;
    detectHeader() {
        this.innerWidth = window.innerWidth;
        this.visible = this.innerWidth >= this.breakpoint;
    }




}