import { AppSettingService } from '../../services/appsetting.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { services } from 'src/app/services/services.service';
@Component({
    selector: 'app-header-style-two',
    templateUrl: './header-style-two.component.html',
    styleUrls: ['./header-style-two.component.scss']
})
export class HeaderStyleTwoComponent implements OnInit {
    address: any;
    telephone1: any;
    twitter: any;
    instagram: any;
    youtube: any;

    ngOnInit(): void {
        this.getAppSettings()
    }

    constructor(private appSettingService: AppSettingService){

    }
    isIconOpen = false;

    toggleIcons() {
        this.isIconOpen = !this.isIconOpen;
    }

    
  getAppSettings() {
    this.appSettingService.getAppSetting().subscribe((data: any) => {
      this.address = data.find(item => item.key === "adres")?.value;
      this.telephone1 = data.find(item => item.key === "telefon1")?.value;
      this.twitter = data.find(item => item.key === "twitter")?.value;
      this.instagram = data.find(item => item.key === "instagram")?.value;
      this.youtube = data.find(item => item.key === "youtube")?.value;

    })
  }

}