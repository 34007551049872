import { HttpClientModule } from "@angular/common/http";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LightgalleryModule } from "lightgallery/angular";
import { CountUpModule } from "ngx-countup";
import { LightboxModule } from "ngx-lightbox";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxScrollTopModule } from "ngx-scrolltop";
import { AppRoutingModule } from "../app-routing.module";
import { AppComponent } from "../app.component";
import { HeaderStyleSixComponent } from "./header-style-six/header-style-six.component";
import { FooterComponent } from "./footer/footer.component";
import { LayoutComponent } from "./layout/layout.component";
import { LeftBoxComponent } from "./left-box/left-box.component";
import { HeaderStyleTwoComponent } from "./header-style-two/header-style-two.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";





@NgModule({
  declarations: [
      LayoutComponent,
      FooterComponent,
      HeaderStyleSixComponent,
      HeaderStyleTwoComponent,
      LeftBoxComponent,


  ],
  imports: [
      CommonModule,
      FormsModule,
      RouterModule,
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      CarouselModule,
      CountUpModule,
      NgxScrollTopModule,
      LightboxModule,
      FormsModule,
      LightgalleryModule,
      HttpClientModule,
      ReactiveFormsModule,
      TranslateModule,
  ],
  exports: [
      LayoutComponent
  ],
  providers: []
})
export class CoreModule { }
