import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageCourseApplication } from 'src/app/models/languageCourseApplication';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LanguageCourseService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) {}

    addLanguageCourse(data: LanguageCourseApplication): Observable<LanguageCourseApplication> {
        const url = `LanguageCourseApplication/Add`;
        return this.http.post<LanguageCourseApplication>(this.path + url, data)

    }
}