import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from 'src/app/models/language';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  path = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getAllLanguage(): Observable<any> {
    const url = `Language/GetAllLanguages`;
    return this.http.get<any>(this.path + url);

  }

}
