import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { services } from '../../../services/services.service';
@Component({
  selector: 'app-courses-details-page',
  templateUrl: './courses-details-page.component.html',
  styleUrls: ['./courses-details-page.component.scss']
})
export class CoursesDetailsPageComponent implements OnInit {

  //Umrah
  pages: any;
  photoUrl: string = environment.imgUrl;

  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activeRoute.data.subscribe(
      (data: { pages: any }) => {
          if (data.pages) {
              this.pages = data.pages;
          }
      });
  }

}
