import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LightgalleryModule } from 'lightgallery/angular';
import { CountUpModule } from 'ngx-countup';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { HappyStudentsFeedbackComponent } from './components/common/happy-students-feedback/happy-students-feedback.component';
import { HeaderStyleThreeComponent } from './components/common/header-style-three/header-style-three.component';
import { InformationApplicationComponent } from './components/common/information-application/information-application.component';
import { InformationResolver } from './components/common/information-application/information.resolver';
import { EventComponent } from './components/pages/applications/event/event.component';
import { FuneralFundComponent } from './components/pages/applications/funeral-fund/funeral-fund';
import { HajjComponent } from './components/pages/applications/hajj/hajj.component';
import { LanguageCourseComponent } from './components/pages/applications/language-course/language-course.component';
import { UmrahComponent } from './components/pages/applications/umrah/umrah.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CoursesDetailsPageComponent } from './components/pages/courses-details-page/courses-details-page.component';
import { DonationCategoryComponent } from './components/pages/donation/donation-category/donation-category.component';
import { DonationComponent } from './components/pages/donation/donation.component';
import { DonationResolver } from './components/pages/donation/donation.resolver';
import { CurrencyInputDirective } from './directives/money.directive';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { HomePageEightComponent } from './components/pages/home-page-eight/home-page-eight.component';
import { HomePageElevenComponent } from './components/pages/home-page-eleven/home-page-eleven.component';
import { HomeelevenMainBannerComponent } from './components/pages/home-page-eleven/homeeleven-main-banner/homeeleven-main-banner.component';
import { HomeComponent } from './components/pages/home/home.component';
import { InstructorsPageTwoComponent } from './components/pages/instructors-page-two/instructors-page-two.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsResolver } from './components/pages/news/news.resolver';
import { WebPagesComponent } from './components/pages/web-pages/web-pages.component';
import { WebPagesResolver } from './components/pages/web-pages/web-pages.resolver';
import { CoreModule } from './core/core.module';
import { createTranslateLoader } from './translater-loader';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { ScholarshipComponent } from './components/pages/applications/scholarship/scholarship.component';
import { AngularIbanModule } from 'angular-iban';
import { IbanPrefixDirective } from './directives/iban-prefix.directive';


@NgModule({
  declarations: [
    AppComponent,
    HeaderStyleThreeComponent,
    InstructorsPageTwoComponent,
    ErrorPageComponent,
    ComingSoonPageComponent,
    ContactPageComponent,
    CoursesDetailsPageComponent,
    HomePageEightComponent,
    HomePageElevenComponent,
    HomeelevenMainBannerComponent,
    HappyStudentsFeedbackComponent,
    BlogComponent,
    FuneralFundComponent,
    NewsComponent,
    WebPagesComponent,
    InformationApplicationComponent,
    HomeComponent,
    DonationComponent,
    DonationCategoryComponent,
    HajjComponent,
    ScholarshipComponent,
    UmrahComponent,
    LanguageCourseComponent,
    EventComponent,
    CurrencyInputDirective,
    OnlyNumberDirective,
    ScholarshipComponent,
    IbanPrefixDirective


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CountUpModule,
    NgxScrollTopModule,
    LightboxModule,
    FormsModule,
    LightgalleryModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatTabsModule,
    BrowserModule,
    GoogleMapsModule,
    MatRadioModule,
    MatExpansionModule,
    LightgalleryModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AngularIbanModule

  ],
  providers: [NewsResolver, WebPagesResolver, InformationResolver, DonationResolver],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
