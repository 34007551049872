import { Associations } from 'src/app/models/associations';
import { services } from 'src/app/services/services.service';;
import { Component, OnInit, HostListener } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

interface MapData {
    center: google.maps.LatLngLiteral;
    markers: MarkerData[];
    name:string;
    address:string;
}

interface MarkerData {
    position: google.maps.LatLngLiteral;
    // label: google.maps.MarkerLabel;
    // options: google.maps.MarkerOptions;
}
@Component({
    selector: 'app-header-style-three',
    templateUrl: './header-style-three.component.html',
    styleUrls: ['./header-style-three.component.scss']
})
export class HeaderStyleThreeComponent implements OnInit {
    mapData: MapData[] = [];
    associations: Associations[] = [];

    constructor(private services: services) { }

    ngOnInit(): void {
        this.services.getAssociation().subscribe((data: Associations[]) => {
            this.associations = data;
            this.setMapData();
        });
    }

    setMapData(): void {
        this.mapData = [];
        for (let i = 0; i < this.associations.length; i++) {
            const association = this.associations[i];
            this.mapData.push({
                center: { lat: association.latitude, lng: association.longitude },
                markers: [{
                    position: { lat: association.latitude, lng: association.longitude },
                    // label: { color: 'black', text: association.name },
                    // options: { animation: google.maps.Animation.BOUNCE }
                }],
                name:association.name,
                address:association.address
            });
         
        }

    }


    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }


    // associations = [
    //     { name: 'Türkisch – Islamischer Verein Aarburg', address: 'Oltenerstrasse 84, 4663 Aarburg' },
    //     { name: 'Türkisch – Islamische Vereinigung Baar', address: 'Gewerbestrasse 9, 6340 Baar' },
    //     { name: 'Bachenbülach Mevlana Moschee', address: 'Länggenstrasse 26, 8184 Bachenbülach' },
    //     { name: 'Türkisch Islamischer Sozial und Kultur Verein Basel', address: 'Leimgrubenweg 6, 4053 Basel' },
    //     { name: 'Türkisch – Islamischer Verein Biel', address: 'Mittelstrasse 35b, 2502 Biel' },
    //     { name: 'Türkisch – Islamischer Verein Buchs', address: 'Brummelstrasse 2, 5033 Buchs' },
    //     { name: 'Bürglen Türkisch-Islamischer Kulturverein', address: 'Bahnhofstrasse 19, 8575 Bürglen' },
    //     { name: 'SMG Schweizerische Muslimische Gemeinschaft', address: 'Gewerbestrasse 8, 6330 Cham' },
    //     { name: 'Türkisch- Islamischer Kulturverein Dällikon', address: 'Industriestrasse 4, 8108 Dällikon' },
    //     { name: 'Türkischer Kulturverein Döttingen', address: 'Hauptstrasse 5, 5312 Döttingen' },
    //     { name: 'Centre Islamique et Culturel Turc de Lausanne et Env.', address: 'Av. Tir-Federal 35, 1024 Ecublens' },
    //     { name: 'Egnach Mevlana Moschee', address: 'Romanshornerstrasse 134, 9322 Egnach' },
    //     { name: 'Islamica Turco-Svizzera', address: 'Via Baragge 1b, 6512 Giubiasco' },
    //     { name: 'Türkisch-Islamischer Kulturverein', address: 'Rietstrasse 1, 9435 Heerbrugg' },
    //     { name: 'Islamisches Glaubengemeinschaft Herzogenbuchsee', address: 'Hofmattstrasse 24, 3360 Herzogenbuchsee' },
    //     { name: 'Türkischer Kulturverein Kreuzlingen', address: 'Bergstrasse 5a, 8280 Kreuzlingen' },
    //     { name: 'Türkischer Kulturverein Langenthal', address: 'Bleienbachstrasse 14, 4900 Langenthal' },
    //     { name: 'Association des Turcs de Monthey', address: 'p.a Maison du Monde, Av. du Chrochetan 42, 1870 Monthey' },
    //     { name: 'Ehli Beyt Camii', address: 'Industriestrasse 3 A, 5702 Niederlenz' },
    //     { name: 'Assocition Culturelle d’anatolie', address: 'Av. de la Gare 41, 2000 Neuchâtel' },
    //     { name: 'Türkischer Verein Glarus', address: 'Molliserstrasse 45, 8754 Netstal' },
    //     { name: 'Association et Centre Culturel Turc Moudon', address: 'Av. de la Gare 13 CP 272, 1510 Moudon' },
    //     { name: 'Bern Türkisch Islamischer Verein', address: 'Oberdorfstrasse 2, 3072 Ostermundigen' },
    //     { name: 'Türkischer Kulturverein-Diyanet Umgebung Rorschach', address: 'Neustadtstrasse 11 a, 9400 Rorschach' },
    //     { name: 'Rheinfelden Merkez Camii', address: 'Gerstenweg 8, 4310 Rheinfelden' },
    //     { name: 'Nouvelle Mosquee de Renens', address: 'Villas du Mont 1, 1020 Renens' },
    //     { name: 'Türkisch – Islamischer Verein', address: 'Hauptstrasse 3, 5734 Reinach' },
    //     { name: 'Centro Culturale Turco Islamico di Lugano', address: 'Via Giuseppe Maggi 4-A, 6963 Pregassona – Lugano' },
    //     { name: 'Fondation Communauté Musulmane-Genève', address: 'Rte de St-Julien 120, 1228 Plan-les-Ouates' },
    //     { name: 'Rüti Türkisch-Islamische Verein', address: 'Bandwiesstrasse 4, 8630 Rüti' },
    //     { name: 'Türkisch Islamischer Verein Eyüp Sultan Moschee', address: 'Meisenstrasse 6, 9000 St. Gallen' },
    //     { name: 'Anatolisch-Helvetischer Verein Fatih Moschee', address: 'Holunderweg 55, 4500 Solothurn' },
    //     { name: 'Verein für soz. Zus. der türk. Arb. in Seon und Umg.', address: 'Birchmattstrasse 5, 5703 Seon' },
    //     { name: 'Islamische Union Uri', address: 'Umfahrungsstrasse 17, 6467 Schattdorf' },
    //     { name: 'Türkisch-Islamischer Verein Aksa Moschee', address: 'Schalterweg 10, 8200 Schaffhausen' },
    //     { name: 'Türkischer Verein', address: 'Essanestrasse 152, 9492 Eschen' },
    //     { name: 'Türkisch-Schweizerischer Kulturverein', address: 'Rotfarb 14, 8730 Uznach' },
    //     { name: 'Wädenswil Türk İslam Birliği', address: 'Moosacherstrasse 5, 8804 Au/ZH' },
    //     { name: 'Islamischer Kulturverein', address: 'Rickenstrasse 9, 9630 Wattwil' },
    //     { name: 'Uster Türkischer Verein Diyanet Moschee', address: 'Zürichstrasse 105, 8606 Werrikon' },
    //     { name: 'Wil Diyanet Camii', address: 'Dufourtstrasse 4-6, 9500 Wil' },
    //     { name: 'Verein Türkgücü Winterthur', address: 'Theaterstrasse 25, 8400 Winterthur' },
    //     { name: 'Winterthur Türkisch-Islamischer Verein', address: 'Bürglistrasse 31 a, 8400 Winterthur' },
    //     { name: 'Islamische Stiftung ISK', address: 'Calandastrasse 11, 8048 Zürich' },
    //     { name: 'Türkisch – Islamische Kulturverein', address: 'Kochstrasse 22, 8004 Zürich' },
    //     { name: 'Zürich Nord Mimar Sinan Moschee', address: 'Schwamendingenstrasse 102, 8050 Zürich' }
    // ];

}