import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { services } from 'src/app/services/services.service';

@Injectable()
export class NewsResolver implements Resolve<any> {
    constructor(private contentService: ContentService) { }
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {
        const id = route.paramMap.get('id');
        return this.contentService.getContentById(id) as Observable<any>;
    }
}