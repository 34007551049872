<section class="brand-section soft-blue-bg mt-30">
        <div class="" >
                <owl-carousel-o [options]="partnerSlides" >

                        <ng-template carouselSlide   *ngFor="let item of smallSlider" >
                                <div  >
                                        <div class="brand-item full-opacity">
                                                <a [href]="item?.url">
                                                        <img [src]="item?.url" alt="">
                                                </a>
                                        </div>

                                </div>
                        </ng-template>
                </owl-carousel-o>
        </div>
</section>


