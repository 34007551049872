import { environment } from 'src/environments/environment';

import { services } from 'src/app/services/services.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instructors-page-two',
  templateUrl: './instructors-page-two.component.html',
  styleUrls: ['./instructors-page-two.component.scss']
})
export class InstructorsPageTwoComponent implements OnInit {
  list: any;
  photoUrl: string = environment.imgUrl
  constructor(private services: services) { }

  ngOnInit(): void {


    this.services.getFrontAllClergy().subscribe((data: any) => {
      this.list = data;

    });

  }





}
