<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">

            <h2>{{'LANGUAGE_COURSE_APPLICATION'|translate}}</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-70 pb-70">
    <div class="container">

        <div class="container application">
            <div class="tab-wrap application">

                <input type="radio" id="tab1" name="tabGroup1" class="tab" checked>
                <label for="tab1">{{'PERSONAL_INFORMATION'|translate}}</label>

                <input type="radio" id="tab2" name="tabGroup1" class="tab" 
                    [disabled]="!this.checkForm(personalInformationForm)">
                <label for="tab2">{{'ADDITIONAL_INFORMATION'|translate}}</label>

                <div class="tab__content personal">
                    <h3>{{'PERSONAL_INFORMATION'|translate}}</h3>
                    <form class="row col md-12" [formGroup]="personalInformationForm">

                        <div class="col-md-6">
                            <label for="firstName" class="form-label">{{ 'NAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="firstName"
                                placeholder="{{ 'NAME' | translate }}" required>
                            <div *ngIf="personalInformationForm.get('firstName')?.invalid && (personalInformationForm.get('firstName')?.dirty || personalInformationForm.get('firstName')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="lastName" class="form-label">{{ 'SURNAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="lastName"
                                placeholder="{{ 'SURNAME' | translate }}" required>
                            <div *ngIf="personalInformationForm.get('lastName')?.invalid && (personalInformationForm.get('lastName')?.dirty || personalInformationForm.get('lastName')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">{{ 'TC_IDENTIFICATION_NUMBER' |translate}}</label>
                            <input type="text" class="form-control" formControlName="identificationNumber"
                                placeholder="xxxxxxxxxxx" maxlength="11">
                            <div *ngIf="personalInformationForm.get('identificationNumber')?.invalid && (personalInformationForm.get('identificationNumber').dirty || personalInformationForm.get('identificationNumber').touched)"
                                class="alert">
                                <div
                                    *ngIf=" personalInformationForm.get('identificationNumber')?.hasError('minlength') ">
                                    {{'PLEASE_ENTER_11_DIGITS'|translate}}
                                </div>

                            </div>

                        </div>
                        <div class="col-md-6">
                            <label for="phone" class="form-label">{{ 'PHONE_NUMBER' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="phone" formControlName="phone"
                                placeholder="{{ 'PHONE_NUMBER' | translate }}">
                            <div *ngIf="personalInformationForm.get('phone').invalid && (personalInformationForm.get('phone').dirty || personalInformationForm.get('phone').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="email" class="form-label">{{ 'EMAIL' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="email" class="form-control" id="email" formControlName="email"
                                placeholder="{{ 'EMAIL' | translate }}">
                            <div *ngIf="personalInformationForm.get('email')?.invalid && (personalInformationForm.get('email').dirty || personalInformationForm.get('email').touched)"
                                class="alert">
                                <div *ngIf="personalInformationForm.get('email').errors?.required">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>
                                <div *ngIf="personalInformationForm.get('email')?.errors?.email" class="alert">
                                    {{ 'PLEASE_ENTER_A_VALID_EMAIL' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="address" class="form-label">{{ 'ADDRESS' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="city" formControlName="address"
                                placeholder="{{ 'ADDRESS' | translate }}">
                            <div *ngIf="personalInformationForm.get('address').invalid && (personalInformationForm.get('address').dirty || personalInformationForm.get('address').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="city" class="form-label">{{ 'CITY' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="city" formControlName="city"
                                placeholder="{{ 'CITY' | translate }}">
                            <div *ngIf="personalInformationForm.get('city').invalid && (personalInformationForm.get('city').dirty || personalInformationForm.get('city').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="plz" class="form-label">{{ 'PLZ' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="plz" formControlName="plz"
                                placeholder="{{ 'PLZ' | translate }}">
                            <div *ngIf="personalInformationForm.get('plz').invalid && (personalInformationForm.get('plz').dirty || personalInformationForm.get('plz').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>


                        <button type="submit" class="btn btn-primary" (click)="nextTab(0)"
                            [disabled]="!this.checkForm(personalInformationForm)">
                            <div *ngIf="!this.checkForm(personalInformationForm)"><label class=" fs-5">*</label>{{
                                'FILL_IN_ALL_FIELDS_TO_PROCEED' | translate
                                }}</div>
                            <div *ngIf="this.checkForm(personalInformationForm)"> {{ 'NEXT' | translate }}</div>
                        </button>
                    </form>

                </div>

                <div class="tab__content">
                    <h3>{{'ADDITIONAL_INFORMATION'|translate}}</h3>
                    <form class="row col-md-12" [formGroup]="otherForm">
                        <div class="row">
                            <label for="fullName"
                                class="form-label">{{'THE_LANGUAGE_COURSE_YOU_WANT_TO_ATTEND'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <div class="row">
                                <div class="col-md-3 mb-2 mt-1" *ngFor="let langs of language; let i=index;">
                                    <mat-accordion>
                                        <mat-expansion-panel hideToggle (opened)="openLangLevel[i] = true"
                                            (closed)="openLangLevel[i] = false">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>

                                                    <div class="checkbox-wrapper-46">
                                                        <input type="checkbox" id="cbx-46" class="inp-cbx"
                                                            [checked]="openLangLevel[i]" [value]="langs.id"
                                                            type="checkbox" id="inlineCheckbox{{langs.id}}" />
                                                        <label for="cbx-46" class="cbx"><span>
                                                                <svg viewBox="0 0 12 10" height="10px" width="12px">
                                                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                                </svg></span><span>{{langs.name |translate }}</span>
                                                        </label>
                                                    </div>


                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div class="row level-menu ">
                                                <label for="fullName" class="form-label">{{'LEVEL'|translate}}<label
                                                        class="text-danger fs-5">*</label></label>
                                                <div class="col-md-2 " *ngFor="let level of langLevel">
                                                    <input type="radio" id="{{langs.id}}{{level.key}}"
                                                        [value]="level.key" name="languageLevel{{i}}">
                                                    <span>{{level.key}}</span>

                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="groupProgramId" class="form-label">{{ 'GROUP_PROGRAM_YOU_WANT_TO_JOIN' | translate }}<label class="text-danger fs-5">*</label></label>
                            <select formControlName="groupProgramId" name="groupProgramId" class="form-control">
                                <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                <option *ngFor="let item of groupProgram" [ngValue]="item.value">
                                    {{item.key|translate}}</option>
                            </select>
                            <div *ngIf="otherForm.get('groupProgramId').invalid && (otherForm.get('groupProgramId').dirty || otherForm.get('groupProgramId').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="hearFromId" class="form-label">{{ 'WHERE_DID_YOU_HEAR_ABOUT_THE_PROGRAM' |translate }}<label class="text-danger fs-5">*</label></label>
                            <select formControlName="hearFromId" name="hearFromId" class="form-control">
                                <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                <option *ngFor="let item of hearfrom" [ngValue]="item.value">
                                    {{item.key|translate}}</option>
                            </select>
                            <div *ngIf="otherForm.get('hearFromId').invalid && (otherForm.get('hearFromId').dirty || otherForm.get('hearFromId').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label for="joiningReason" class="form-label">{{ 'YOUR_REASON_FOR_JOINING_THE_PROGRAM' |translate }}</label>
                            <textarea type="text" rows="3" class="form-control" id="joiningReason" formControlName="joiningReason"
                                placeholder="{{ 'YOUR_REASON_FOR_JOINING_THE_PROGRAM' | translate }}"></textarea>
                            <div *ngIf="otherForm.get('joiningReason').invalid && (otherForm.get('joiningReason').dirty || otherForm.get('joiningReason').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label for="requestAndSuggestion" class="form-label">{{ 'YOUR_REQUESTS_AND_SUGGESTIONS' |translate }}</label>
                            <textarea type="text"  rows="3" class="form-control"  id="requestAndSuggestion"
                                formControlName="requestAndSuggestion"
                                placeholder="{{ 'YOUR_REQUESTS_AND_SUGGESTIONS' | translate }}"></textarea>
                            <div *ngIf="otherForm.get('requestAndSuggestion').invalid && (otherForm.get('requestAndSuggestion').dirty || personalInformationForm.get('requestAndSuggestion').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>

                        <button [disabled]="isLoading || !personalInformationForm.valid || !otherForm.valid "
                            (click)="saveForm() " class="btn btn-primary">
                            <span *ngIf="!isLoading">{{'SUBMIT'|translate}}</span>
                            <span *ngIf="isLoading">
                                <i class="fa fa-spinner fa-spin"></i> {{'SENDING'|translate}}
                            </span>
                        </button>

                    </form>


                </div>

            </div>

        </div>
    </div>
</div>