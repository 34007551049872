
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Footer } from 'src/app/models/footer';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class FooterService {
    path = environment.apiUrl;
    languageCode: string = "";

    constructor(private http: HttpClient) {
        this.languageCode = localStorage.getItem("language");
    }

    getFooter(): Observable<Footer[]> {
        const url = this.path + `GetAllFooterItem?languageCode=${this.languageCode}`;
        return this.http.get<Footer[]>(url);
    }
}