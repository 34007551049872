<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-footer-icon">
                    <h3>{{'CONTACT'|translate}}</h3>
                    <ul class="contact-us-link">
                        <li>
                            
                                <i class='bx bx-map contact-icon'> <a href="https://www.google.com/maps/place/{{address}}">{{ address }}</a></i>

                           
                        </li>
                        <li>
                            
                                <i class='bx bx-phone-call call contact-icon'> <a href="tel:{{ telephone }}">{{
                                        telephone}}</a></i>

                           
                        </li>
                        <li>
                            
                                <i class='fa-regular fa-envelope'><a href="mailto:{{ mail }}"> {{ mail }}</a></i>

                           
                        </li>
                        <li >
                            
                                <i class='fa-regular fa-clock'><a class="hour">{{'WEEKDAY'|translate}} {{ hour }}</a></i>
                           
                        </li>

                    </ul>
                </div>
                <div class="single-footer-widget mb-30">

                    <ul class="social-link">
                        <li><a href="{{ facebook }}" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a>
                        </li>
                        <li><a href="{{ twitter }}" class="d-block" target="_blank"><i class='bi bi-twitter-x'></i></a>
                        </li>
                        <li><a href="{{ instagram }}" class="d-block" target="_blank"><i
                                    class='bx bxl-instagram'></i></a></li>
                        <li><a href="{{ youtube }}" class="d-block" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of footerArea">

                <div class="single-footer-widget mb-30">
                    <h3>{{item.areaName}}</h3>
                    <ul class="support-link">
                        <li *ngFor="let footeritems of item.footerItems">
                            <a [href]="footeritems?.linkUrl ? footeritems?.linkUrl : (footeritems.slug ? footeritems.slug : '' ) "
                                [target]="footeritems?.linkUrl ? '_blank' : ''">{{footeritems?.name}}</a>
                        </li>


                    </ul>
                </div>
            </div>

        </div>
    </div>
    <hr class="custom-seperator container">
    <div class="footer-bottom-area">
        <div class="container">
            <p>© Designed and Developed by <a rel="noopener" href="https://vakifglobal.com/"
                    style="display:unset !important;" target="_blank"><img
                        src="../../../../assets/img/logo/vakifGlobalLogo.png"
                        style="width:11%; margin-left:3px; " alt=""></a></p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>