import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HajjService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) { }

    addHajjForm(data: any): Observable<any> {
        const url = `HacForm/HajjRegistrationForm`;
        return this.http.post(this.path + url, data);
    }

    getHajjPeriods(): Observable<any> {
        const url = `Period/GetListHP`;
        return this.http.get(this.path);
    }

}