import { AppSetting } from 'src/app/models/appsetting';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { LanguageCourseService } from 'src/app/services/languageCourse.service';
import { CheckBoxOption } from 'src/app/models/checkboxOption';
import { LanguageCourseApplication } from 'src/app/models/languageCourseApplication';
import Swal from 'sweetalert2';
import { AppSettingService } from 'src/app/services/appsetting.service';
import { forkJoin } from 'rxjs';
import { Language } from 'src/app/models/language';
import { ApplicationService } from 'src/app/services/application.service';
import { DynamicValue } from 'src/app/models/dynamicValue';

@Component({
  selector: 'app-language-course',
  templateUrl: './language-course.component.html',
  styleUrls: ['./language-course.component.scss']
})
export class LanguageCourseComponent implements OnInit {
  isLoading: boolean = false;
  personalInformationForm: UntypedFormGroup;
  otherForm: UntypedFormGroup;
  application: LanguageCourseApplication;
  isValid: boolean = false;
  language: Language[] = []
  openLangLevel: boolean[] = [];
  groupProgram: DynamicValue[] = [];
  hearfrom: DynamicValue[] = []
  langLevel: DynamicValue[] = []

  constructor(private fb: FormBuilder, private languageCourseService: LanguageCourseService, private languageService: LanguageService, private appSettingService: AppSettingService, private applicationService: ApplicationService) { }

  ngOnInit(): void {
    this.createForm();
    this.createLanguageForm();
    this.getFormLanguage();
    this.getDynamicValues();
  }



  checkForm(form: AbstractControl): boolean {
    this.isValid = form.dirty && form.valid && form.touched;
    return this.isValid;
  }

  nextTab(currentTabIndex: number) {
    currentTabIndex++;
    const tabId = `tab${currentTabIndex + 1}`;
    const tabRadio = document.getElementById(tabId) as HTMLInputElement;
    if (tabRadio) {
      tabRadio.click();
    }


  }


  getFormLanguage() {
    let languagesList: any[] = [];

    const services = [
      this.appSettingService.getAppSetting(),
      this.languageService.getAllLanguage()

    ]
    forkJoin(services).subscribe(([appSettingData, allLanguageData]) => {
      languagesList = appSettingData.find(item => item.key == "dilkursubasvurudilleri")?.value.split(',').map(Number);

      this.language = allLanguageData
        .filter(language => languagesList.includes(language.id));

    })
  }

  getDynamicValues() {
    this.applicationService.getDynamicValue().subscribe((data: DynamicValue[]) => {
      this.groupProgram = data.filter(value => value.typeId === 1);
      this.hearfrom = data.filter(value => value.typeId === 2);
      this.langLevel = data.filter(value => value.typeId === 3);
    })
  }

  createForm() {
    this.personalInformationForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      identificationNumber: ['', Validators.minLength(11)],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      plz: ['', [Validators.required]]
    })

    this.otherForm = this.fb.group({
      languages: this.fb.array([]),
      groupProgramId: [''],
      hearFromId: [''],
      joiningReason: [''],
      requestAndSuggestion: ['']
    })
  }

  createLanguageForm() {
    return this.fb.group({
      languageId: [''],
      languageLevel: ['']
    });
  }

  get languages() {
    return this.otherForm.get('languages') as FormArray;
  }

  createMainBuildObject(): any {
    const application: any = {
      firstName: this.personalInformationForm.get('firstName').value.toUpperCase(),
      lastName: this.personalInformationForm.get('lastName').value.toUpperCase(),
      identificationNumber: this.personalInformationForm.get('identificationNumber').value,
      phone: this.personalInformationForm.get('phone').value,
      email: this.personalInformationForm.get('email').value,
      address: this.personalInformationForm.get('address').value.toUpperCase(),
      city: this.personalInformationForm.get('city').value.toUpperCase(),
      plz: this.personalInformationForm.get('plz').value.toUpperCase(),

      groupProgramId:Number(this.otherForm.get('groupProgramId').value) ,
      hearFromId: Number(this.otherForm.get('hearFromId').value),
      joiningReason: this.otherForm.get('joiningReason').value,
      requestAndSuggestion: this.otherForm.get('requestAndSuggestion').value,
      languages: this.languages.value

    };
    return application;

  }


  saveForm() {
    this.isLoading = true;
    while (this.languages.value.length !== 0) {
      this.languages.value.splice(0, 1)
    }

    var datas = [];
    var indatas = [];

    this.language.forEach(element => {
      const data = document.
        getElementById("inlineCheckbox" + element.id) as
        HTMLInputElement;
      if (data.checked) {
        datas.push(data.value);
        this.langLevel.forEach(item => {
          const indata = document.
            getElementById(element.id.toString() + item.key.toString()) as
            HTMLInputElement;
          if (indata.checked) {
            indatas.push(indata.value);
            this.languages.value.push(
              {
                languageId: Number(data.value),
                languageLevel: indata.value
              }
            )
          }
        });
      }
    });
    if (this.personalInformationForm.valid && this.otherForm.valid && datas.length > 0 && indatas.length > 0) {

      this.application = this.createMainBuildObject();
      this.languageCourseService.addLanguageCourse(this.application).subscribe((data: any) => {
        if (data) {
          this.isLoading = false;
          Swal.fire({
            icon: 'success',
            title: 'Başarılı',
            text: 'Başvurunuz başarıyla gönderildi!',
            confirmButtonText: 'Kapat',

          })
            .then((success) => {
              window.location.reload();
            });
        }


      }, (error: any) => {

        this.isLoading = false;
        {
          // console.error('servisten sonra hata', error);
          Swal.fire({
            icon: 'error',
            title: 'Hata',
            text: error.error[0],
            confirmButtonText: 'Kapat'

          }
          )

        }
      }

      )
    }
    else {
      this.isLoading = false;
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Başvurunuz sırasında bir hata oluştu',
        confirmButtonText: 'Kapat'
      }
      )
    }
  }

}
