import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { HomePageEightComponent } from './components/pages/home-page-eight/home-page-eight.component';
import { InstructorsPageTwoComponent } from './components/pages/instructors-page-two/instructors-page-two.component';

import { WebPagesComponent } from './components/pages/web-pages/web-pages.component';
import { WebPagesResolver } from './components/pages/web-pages/web-pages.resolver';

import { HeaderStyleThreeComponent } from './components/common/header-style-three/header-style-three.component';
import { EventComponent } from './components/pages/applications/event/event.component';
import { FuneralFundComponent } from './components/pages/applications/funeral-fund/funeral-fund';
import { HajjComponent } from './components/pages/applications/hajj/hajj.component';
import { LanguageCourseComponent } from './components/pages/applications/language-course/language-course.component';
import { ScholarshipComponent } from './components/pages/applications/scholarship/scholarship.component';
import { UmrahComponent } from './components/pages/applications/umrah/umrah.component';
import { LayoutComponent } from './core/layout/layout.component';
import { DonationCategoryComponent } from './components/pages/donation/donation-category/donation-category.component';
import { DonationComponent } from './components/pages/donation/donation.component';
import { DonationResolver } from './components/pages/donation/donation.resolver';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsResolver } from './components/pages/news/news.resolver';


const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [

            { path: '', component: HomePageEightComponent },
            { path: 'yakinda', component: ComingSoonPageComponent },
            { path: 'iletisim', component: ContactPageComponent },
            { path: 'hac-basvuru', component: HajjComponent },
            { path: 'burs-basvuru', component: ScholarshipComponent },
            { path: 'umre-basvuru', component: UmrahComponent },
            { path: 'dil-kurs-basvuru', component: LanguageCourseComponent },
            { path: 'cenaze-fonu-basvuru', component: FuneralFundComponent },
            { path: 'dernekler', component: HeaderStyleThreeComponent },
            { path: 'din-gorevlilerimiz', component: InstructorsPageTwoComponent },
            // {
            //     path: 'bagis/:id', component: DonationComponent,
            //     resolve: { data: DonationResolver }
            // },
            // { path: 'bagis-yap', component: DonationCategoryComponent },
            // {
            //     path: 'haber-detay/:id', component: NewsComponent,
            //     resolve: { data: NewsResolver }
            // },
            { path: 'personel', component: InstructorsPageTwoComponent },
            { path: 'etkinlik-basvuru', component: EventComponent },

            {
                path: ':slug/:id', component: WebPagesComponent,
                resolve: { data: WebPagesResolver }
            },


            // Here add new pages component

            { path: '**', component: HomePageEightComponent } // This line will remain down from the whole pages component list
        ]
    }];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }