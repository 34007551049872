
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class PrayerTimeService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) {}

    getFrontAllCountry(): Observable<any> {
        const url = this.path + `Country/GetList`;
        return this.http.get(url);
    }

    //ankara long:32.53 lat:39.57
    getPrayerTimes(long: number, lat: number) {
        const url = `PlaceAPI/GetByDailyCityId?longitude=${long}&latitude=${lat}`;
        return this.http.get('https://admin.awqatsalah.com/api/' + url);
    }

    getFrontCountryByLangcode(code): Observable<any> {
        const url = this.path + `Country/GetByLangcode?langcode=${code}`;
        return this.http.get(url);

    }

    getCountryCitiesById(countryId: number): Observable<any> {
        const url = this.path + `Country/GetById?id=${countryId}`;
        return this.http.get(url);
    }

    getFrontCityById(id): Observable<any> {
        const url = this.path + `City/GetById?id=${id}`;
        return this.http.get(url);

    }
}