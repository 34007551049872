<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">

            <h2>{{'SCHOLARSHIP_APPLICATION'|translate}}</h2>
        </div>
    </div>
</div>
<div class="instructor-area pt-70 pb-70" *ngIf="!isActiveForm">
    <div class="container">
        <div class="container application">
            <div class="tab-wrap ">
                <div class="alert alert-warning" role="alert" >
                    {{bursaryInfo}}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="instructor-area pt-70 pb-70" *ngIf="isActiveForm">
    <div class="container">

        <div class="container application">
            <div class="tab-wrap application">

                <input type="radio" id="tab1" name="tabGroup1" class="tab" checked>
                <label for="tab1">{{'PERSONAL_INFORMATION'|translate}}</label>

                <input type="radio" id="tab2" name="tabGroup1" class="tab"
                    [disabled]="!this.checkForm(personalInformationForm)">
                <label for="tab2">{{'ADDRESS_CONTACT_DETAILS'|translate}}</label>

                <input type="radio" id="tab3" name="tabGroup1" class="tab" [disabled]="!this.checkForm(addressForm)">
                <label for="tab3">{{'FAMILY_INFO'|translate}}</label>

                <input type="radio" id="tab4" name="tabGroup1" class="tab" [disabled]="!this.checkForm(familyForm)">
                <label for="tab4">{{'ADDITIONAL_INFORMATION'|translate}}</label>

                <div class="tab__content personal">
                    <h3>{{'PERSONAL_INFORMATION'|translate}}</h3>
                    <form class="row col md-12" [formGroup]="personalInformationForm">

                        <div class="col-md-6">
                            <label for="fullName" class="form-label">{{ 'FULL_NAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="fullName"
                                placeholder="{{ 'FULL_NAME' | translate }}" required>
                            <div *ngIf="personalInformationForm.get('fullName')?.invalid && (personalInformationForm.get('fullName')?.dirty || personalInformationForm.get('fullName')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="roomType" class="form-label">{{'STUDENTS_SITUATION'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <select id="roomType" formControlName="bursaryTypeId" class="form-select">
                                <option value="" selected disabled>{{'SELECT'|translate}}</option>
                                <option *ngFor="let item of bursaryTypes" [ngValue]="item?.id">{{ languageCode==='de' ?
                                    item?.nameDE : item.name}}
                                </option>
                            </select>
                            <div *ngIf="personalInformationForm.get('bursaryTypeId')?.invalid && (personalInformationForm.get('bursaryTypeId').dirty || personalInformationForm.get('bursaryTypeId').touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>


                        </div>

                        <div class="col-md-6">
                            <label class="form-label">{{ 'TC_IDENTIFICATION_NUMBER' |translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="identificationNumber"
                                placeholder="xxxxxxxxxxx" maxlength="11" onlyNumber>
                            <div *ngIf="personalInformationForm.get('identificationNumber')?.invalid && (personalInformationForm.get('identificationNumber').dirty || personalInformationForm.get('identificationNumber').touched)"
                                class="alert">
                                <div *ngIf="personalInformationForm.get('identificationNumber')?.errors?.required">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>
                                <div
                                    *ngIf=" personalInformationForm.get('identificationNumber')?.hasError('minlength') ">
                                    {{'PLEASE_ENTER_11_DIGITS'|translate}}
                                </div>

                            </div>

                        </div>
                        <div class="col-md-6">
                            <label for="birthDate" class="form-label">{{ 'BIRTHDAY' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="date" class="form-control" id="birthDate" formControlName="birthDate"
                                max="{{date | date:'yyyy-MM-dd'}}">
                            <div *ngIf="personalInformationForm.get('birthDate').invalid && (personalInformationForm.get('birthDate').dirty || personalInformationForm.get('birthDate').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-12">
                            <label for="birthPlace" class="form-label">{{ 'BIRTH_PLACE' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="birthPlace" formControlName="birthPlace"
                                placeholder="{{ 'BIRTH_PLACE' | translate }}">
                            <div *ngIf="personalInformationForm.get('birthPlace').invalid && (personalInformationForm.get('birthPlace').dirty || personalInformationForm.get('birthPlace').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary" (click)="nextTab(0)"
                            [disabled]="!this.checkForm(personalInformationForm)">
                            <div *ngIf="!this.checkForm(personalInformationForm)"><label class=" fs-5">*</label>
                                {{'FILL_IN_ALL_FIELDS_TO_PROCEED' | translate}}</div>
                            <div *ngIf="this.checkForm(personalInformationForm)"> {{ 'NEXT' | translate }}</div>
                        </button>
                    </form>

                </div>

                <div class="tab__content">
                    <h3>{{'ADDRESS_CONTACT_DETAILS'|translate}}</h3>
                    <form class="row col md-12" [formGroup]="addressForm">
                        <div class="col-md-6">
                            <label for="phoneNumber" class="form-label">{{ 'PHONE_NUMBER' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" onlyNumber maxlength="11" class="form-control" id="phoneNumber"
                                formControlName="phoneNumber" placeholder="{{ 'PHONE_NUMBER' | translate }}">
                            <div *ngIf="addressForm.get('phoneNumber').invalid && (addressForm.get('phoneNumber').dirty || addressForm.get('phoneNumber').touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <label for="landlinePhone" class="form-label">{{ 'LANDLINE_TELEPHONE' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" onlyNumber maxlength="15" class="form-control" id="landlinePhone"
                                formControlName="landlinePhone" placeholder="{{ 'LANDLINE_TELEPHONE' | translate }}">
                            <div *ngIf="addressForm.get('landlinePhone').invalid && (addressForm.get('landlinePhone').dirty || addressForm.get('landlinePhone').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div> -->
                        <div class="col-md-6">
                            <label for="email" class="form-label">{{ 'EMAIL' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="email" class="form-control" id="email" formControlName="email"
                                placeholder="{{ 'EMAIL' | translate }}">
                            <div *ngIf="addressForm.get('email')?.invalid && (addressForm.get('email').dirty || addressForm.get('email').touched)"
                                class="alert">
                                <div *ngIf="addressForm.get('email').errors?.required">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>
                                <div *ngIf="addressForm.get('email')?.errors?.email" class="alert">
                                    {{ 'PLEASE_ENTER_A_VALID_EMAIL' | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="corresponceAdress" class="form-label">{{ 'CORRESPONDENCE_ADDRESS' | translate
                                }}<label class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="corresponceAdress"
                                formControlName="corresponceAdress"
                                placeholder="{{ 'CORRESPONDENCE_ADDRESS' | translate }}">
                            <div *ngIf="addressForm.get('corresponceAdress')?.invalid && (addressForm.get('corresponceAdress').dirty || addressForm.get('corresponceAdress').touched)"
                                class="alert">
                                <div *ngIf="addressForm.get('corresponceAdress').errors?.required">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="adres" class="form-label">{{ 'PROVINCE_DISTRICT' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="provinceAndDistrict" rows="3"
                                formControlName="provinceAndDistrict"
                                placeholder="{{ 'PROVINCE_DISTRICT' | translate }}">
                            <div *ngIf="addressForm.get('provinceAndDistrict').invalid && (addressForm.get('provinceAndDistrict').dirty || addressForm.get('provinceAndDistrict').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>


                        <button type="submit" class="btn btn-primary" (click)="nextTab(1)"
                            [disabled]="!this.checkForm(addressForm)">
                            <div *ngIf="!this.checkForm(addressForm)">{{ 'FILL_IN_ALL_FIELDS_TO_PROCEED' | translate
                                }}</div>
                            <div *ngIf="this.checkForm(addressForm)"> {{ 'NEXT' | translate }}</div>
                        </button>
                    </form>
                </div>

                <div class="tab__content">
                    <h3>{{'FAMILY_INFO'|translate}}</h3>
                    <form class="row col md-12" [formGroup]="familyForm">

                        <div class="col-md-6">
                            <label for="fatherFullName" class="form-label">{{ 'YOUR_FATHERS_NAME_AND_SURNAME' |
                                translate
                                }}<label class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="fatherFullName"
                                placeholder="{{ 'YOUR_FATHERS_NAME_AND_SURNAME' | translate }}"
                                formControlName="fatherFullName">
                            <div *ngIf="familyForm.get('fatherFullName').invalid && (familyForm.get('fatherFullName').dirty || familyForm.get('fatherFullName').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="row">
                                <label for="doesFatherWork" class="form-label">{{
                                    'DOES_YOUR_FATHER_WORK' | translate
                                    }}?<label class="text-danger fs-5">*</label></label>
                            </div>
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                formControlName="doesFatherWork">
                                <mat-radio-button class="example-radio-button" *ngFor="let option of checkBoxOption"
                                    [value]="option.value" (change)="onSelectionChange($event,1)"
                                    [selected]="option.id==1 ? true: false">{{option.name|translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <ng-container *ngIf="fatherWork">
                            <div class="col-md-6 fade-in-down">
                                <label for="fatherJob" class="form-label">{{ 'YOUR_FATHERS_OCCUPATION' | translate
                                    }}<label class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" id="fatherJob" formControlName="fatherJob"
                                    placeholder="{{ 'YOUR_FATHERS_OCCUPATION' | translate }}">
                                <div *ngIf="familyForm.get('fatherJob').invalid && (familyForm.get('fatherJob').dirty || familyForm.get('fatherJob').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>
                            </div>



                            <div class="col-md-6 fade-in-down">
                                <label for="fatherWorkPlace" class="form-label">{{ 'NAME_OF_YOUR_FATHERS_WORKPLACE' |
                                    translate }}<label class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" id="fatherWorkPlace"
                                    formControlName="fatherWorkPlace"
                                    placeholder="{{ 'NAME_OF_YOUR_FATHERS_WORKPLACE' | translate }}">
                                <div *ngIf="familyForm.get('fatherWorkPlace').invalid && (familyForm.get('fatherWorkPlace').dirty || familyForm.get('fatherWorkPlace').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>
                            </div>

                        </ng-container>


                        <div class="col-md-12">
                            <label for="fatherMonthlyEarnings" class="form-label">{{ 'YOUR_FATHERS_MONTHLY_EARNINGS' |
                                translate }}<label class="text-danger fs-5">*</label></label>
                            <input type="number" class="form-control" id="fatherMonthlyEarnings"
                                formControlName="fatherMonthlyEarnings"
                                placeholder="{{ 'YOUR_FATHERS_MONTHLY_EARNINGS' | translate }}">
                            <div *ngIf="familyForm.get('fatherMonthlyEarnings').invalid && (familyForm.get('fatherMonthlyEarnings').dirty || familyForm.get('fatherMonthlyEarnings').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="motherFullName" class="form-label">{{ 'YOUR_MOTHERS_NAME_AND_SURNAME' |
                                translate }}<label class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="motherFullName" formControlName="motherFullName"
                                placeholder="{{ 'YOUR_MOTHERS_NAME_AND_SURNAME' | translate }}">
                            <div *ngIf="familyForm.get('motherFullName').invalid && (familyForm.get('motherFullName').dirty || familyForm.get('motherFullName').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <label for="doesMotherWork" class="form-label">{{
                                    'DOES_YOUR_MUM_WORK' | translate
                                    }}?<label class="text-danger fs-5">*</label></label>
                            </div>
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                formControlName="doesMotherWork">
                                <mat-radio-button class="example-radio-button" *ngFor="let option of checkBoxOption"
                                    [value]="option.value" (change)="onSelectionChange($event,2)"
                                    [selected]="option.id==1 ? true: false">{{option.name|translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <ng-container *ngIf="motherWork">
                            <div class="col-md-6 fade-in-down">
                                <label for="motherJob" class="form-label">{{ 'YOUR_MUMS_OCCUPATION' | translate }}<label
                                        class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" id="motherJob" formControlName="motherJob"
                                    placeholder="{{ 'YOUR_MUMS_OCCUPATION' | translate }}">
                                <div *ngIf="familyForm.get('motherJob').invalid && (familyForm.get('motherJob').dirty || familyForm.get('motherJob').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>
                            </div>
                            <div class="col-md-6 fade-in-down">
                                <label for="motherWorkPlace" class="form-label">{{ 'NAME_OF_YOUR_MOTHERS_WORKPLACE' |
                                    translate }}<label class="text-danger fs-5">*</label></label>
                                <input type="text" class="form-control" id="motherWorkPlace"
                                    formControlName="motherWorkPlace"
                                    placeholder="{{ 'NAME_OF_YOUR_MOTHERS_WORKPLACE' | translate }}">
                                <div *ngIf="familyForm.get('motherWorkPlace').invalid && (familyForm.get('motherWorkPlace').dirty || familyForm.get('motherWorkPlace').touched)"
                                    class="alert">

                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                                </div>
                            </div>

                        </ng-container>
                        <div class="col-md-12">
                            <label for="motherMonthlyEarnings" class="form-label">{{ 'YOUR_MOTHERS_MONTHLY_EARNINGS' |
                                translate }}<label class="text-danger fs-5">*</label></label>
                            <input type="number" class="form-control" id="motherMonthlyEarnings"
                                formControlName="motherMonthlyEarnings"
                                placeholder="{{ 'YOUR_MOTHERS_MONTHLY_EARNINGS' | translate }}">
                            <div *ngIf="familyForm.get('motherMonthlyEarnings').invalid && (familyForm.get('motherMonthlyEarnings').dirty || familyForm.get('motherMonthlyEarnings').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="dependOnPersonCount" class="form-label">{{ 'NUMBER_OF_DEPENDENTS_OF_THE_FAMILY'
                                | translate }}<label class="text-danger fs-5">*</label></label>
                            <input type="number" class="form-control" id="dependOnPersonCount"
                                formControlName="dependOnPersonCount"
                                placeholder="{{ 'NUMBER_OF_DEPENDENTS_OF_THE_FAMILY' | translate }}">
                            <div *ngIf="familyForm.get('dependOnPersonCount').invalid && (familyForm.get('dependOnPersonCount').dirty || familyForm.get('dependOnPersonCount').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="familyMonthlyIncome" class="form-label">{{ 'TOTAL_MONTHLY_INCOME_OF_YOUR_FAMILY'
                                | translate }}<label class="text-danger fs-5">*</label></label>
                            <input type="number" class="form-control" id="familyMonthlyIncome"
                                formControlName="familyMonthlyIncome"
                                placeholder="{{ 'TOTAL_MONTHLY_INCOME_OF_YOUR_FAMILY' | translate }}">
                            <div *ngIf="familyForm.get('familyMonthlyIncome').invalid && (familyForm.get('familyMonthlyIncome').dirty || familyForm.get('familyMonthlyIncome').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary" (click)="nextTab(2)"
                            [disabled]="!this.checkForm(familyForm)">
                            <div *ngIf="!this.checkForm(familyForm)">{{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate
                                }}</div>
                            <div *ngIf="this.checkForm(familyForm)"> {{ 'NEXT' | translate }}</div>
                        </button>
                    </form>
                </div>

                <div class="tab__content">
                    <h3>{{'SCHOOL_INFORMATION'|translate}}</h3>
                    <form class="row col-md-12" [formGroup]="otherForm">

                        <div class="col-md-6">
                            <label for="lastGraduatedSchool" class="form-label">{{ 'LAST_GRADUATED_SCHOOL' | translate
                                }}<label class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="lastGraduatedSchool"
                                formControlName="lastGraduatedSchool"
                                placeholder="{{ 'LAST_GRADUATED_SCHOOL' | translate }}">
                            <div *ngIf="otherForm.get('lastGraduatedSchool').invalid && (otherForm.get('lastGraduatedSchool').dirty || otherForm.get('lastGraduatedSchool').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="attendSchool" class="form-label">{{ 'SCHOOL_EDUCATIONAL_INSTITUTION_YOU_ATTEND'
                                | translate }}<label class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="attendSchool" formControlName="attendSchool"
                                placeholder="{{ 'SCHOOL_EDUCATIONAL_INSTITUTION_YOU_ATTEND' | translate }}">
                            <div *ngIf="otherForm.get('attendSchool').invalid && (otherForm.get('attendSchool').dirty || otherForm.get('attendSchool').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-12">
                            <label for="section" class="form-label">{{ 'SECTION' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="section" formControlName="section"
                                placeholder="{{ 'SECTION' | translate }}">
                            <div *ngIf="otherForm.get('section').invalid && (otherForm.get('section').dirty || otherForm.get('section').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        &nbsp;
                        <h3>{{'BANK_INFORMATION'|translate}}</h3>

                        <div class="col-md-6">
                            <label for="bankName" class="form-label">{{ 'BANK_NAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="bankName" formControlName="bankName"
                                placeholder="{{ 'BANK_NAME' | translate }}">
                            <div *ngIf="otherForm.get('bankName').invalid && (otherForm.get('bankName').dirty || otherForm.get('bankName').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="iban" class="form-label">{{ 'IBAN' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="iban" name="iban" formControlName="iban"
                                placeholder="{{ 'IBAN' | translate }}" appIbanPrefix>
                            <div *ngIf="otherForm.get('iban')?.invalid && (otherForm.get('iban')?.dirty || otherForm.get('iban')?.touched)"
                                class="alert alert-danger">

                                <div *ngIf="otherForm.get('iban')?.errors?.['iban']">
                                    {{'IBAN_INVALID'|translate}}
                                </div>

                            </div>
                            <div *ngIf="otherForm.get('iban')?.valid && (otherForm.get('iban')?.dirty || otherForm.get('iban')?.touched)"
                                class="alert alert-success">
                                {{'IBAN_VALID'|translate}}
                            </div>
                        </div>
                        <h3>{{'ADDITIONAL_DOCUMENTS'|translate}}
                        </h3>

                        <div class="col-md-6">
                            <label for="incomeDeclarationDocument"
                                class="form-label">{{'INCOME_DECLARATION_CERTIFICATE_PAYROLL'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="file" class="form-control" id="incomeDeclarationDocument"
                                (change)="uploadFileEvent($event)"
                                accept="image/png, image/jpeg, image/jpg, application/pdf">
                            <div *ngIf="fileSize.incomeDeclarationDocument" class="alert">
                                {{ fileSize.incomeDeclarationDocument}}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="identificationPhotoCopy"
                                class="form-label">{{'PHOTOCOPY_OF_STUDENTS_IDENTITY_CARD'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="file" class="form-control" id="identificationPhotoCopy"
                                (change)="uploadFileEvent($event)"
                                accept="image/png, image/jpeg, image/jpg, application/pdf">
                            <div *ngIf="fileSize.identificationPhotoCopy" class="alert">
                                {{ fileSize.identificationPhotoCopy }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="currentStudentCertificate"
                                class="form-label">{{'CURRENT_STUDENT_CERTIFICATE'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="file" class="form-control" id="currentStudentCertificate"
                                (change)="uploadFileEvent($event)"
                                accept="image/png, image/jpeg, image/jpg, application/pdf">
                            <div *ngIf="fileSize.currentStudentCertificate" class="alert">
                                {{ fileSize.currentStudentCertificate }}
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="identityRegisterCopy"
                                class="form-label">{{'POPULATION_REGISTRATION_SAMPLE'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="file" class="form-control" id="identityRegisterCopy"
                                (change)="uploadFileEvent($event)"
                                accept="image/png, image/jpeg, image/jpg, application/pdf">
                            <div *ngIf="fileSize.identityRegisterCopy" class="alert">
                                {{ fileSize.currentStudentCertificate }}
                            </div>
                        </div>
                        <button
                            [disabled]="isLoading || !personalInformationForm.valid || !addressForm.valid || !familyForm.valid || !otherForm.valid"
                            (click)="saveForm() " class="btn btn-primary">
                            <span *ngIf="!isLoading">{{'SUBMIT'|translate}}</span>
                            <span *ngIf="isLoading">
                                <i class="fa fa-spinner fa-spin"></i> {{'SENDING'|translate}}
                            </span>
                            <!-- <div *ngIf="!isLoading" routerLink=""></div> -->
                        </button>

                    </form>


                </div>

            </div>

        </div>
    </div>
</div>