import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appIbanPrefix]'
})
export class IbanPrefixDirective implements OnInit {
  private readonly IBAN_PREFIX = 'TR';
  private readonly MAX_LENGTH = 31; // TR + 24 numara + 5 boşluk (4 grup arası)

  constructor(private control: NgControl, private el: ElementRef) {

  }
  ngOnInit() {
    // İlk yüklemede input alanına TR prefixini ekle
    this.setInitialValue();
  }

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Prefixi kontrol et, silinmişse tekrar ekle
    if (!value.startsWith(this.IBAN_PREFIX)) {
      value = this.IBAN_PREFIX + value.replace(this.IBAN_PREFIX, '');
    }

    // TR'den sonraki kısımda sadece sayısal karakterlere izin ver
    let numericPart = value.slice(this.IBAN_PREFIX.length).replace(/\D/g, '');

    // Girilen her 4 sayıdan sonra boşluk ekle
    numericPart = numericPart.match(/.{1,4}/g)?.join(' ') || numericPart;

    // Eğer uzunluk sınırını aşıyorsa, fazla karakterleri çıkar
    if ((numericPart + this.IBAN_PREFIX).length > this.MAX_LENGTH) {
      numericPart = numericPart.slice(0, this.MAX_LENGTH - this.IBAN_PREFIX.length);
    }

    //Güncellenmiş değeri input alanına set et
    this.control.control?.setValue(this.IBAN_PREFIX + numericPart, { emitEvent: false });
  }

  private setInitialValue(): void {
    const currentValue = this.control.control?.value || '';
    let numericPart = currentValue.replace(/\D/g, '');
    let formattedValue = numericPart.match(/.{1,4}/g)?.join(' ') || numericPart;

    // Eğer uzunluk sınırını aşıyorsa, fazla karakterleri çıkar
    if ((this.IBAN_PREFIX + formattedValue).length > this.MAX_LENGTH) {
      formattedValue = formattedValue.slice(0, this.MAX_LENGTH - this.IBAN_PREFIX.length);
    }

    this.control.control?.setValue(this.IBAN_PREFIX + formattedValue, { emitEvent: false });
  }
}
