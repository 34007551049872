<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="">{{'HOME'|translate}}</a></li>
                <li>{{'ASSOCIATIONS'|translate}}</li>
            </ul> -->
            <h2>{{'ASSOCIATIONS'|translate}}</h2>
        </div>
    </div>
</div>

<div class="events-area pt-100 pb-70">
    <div class="container">
        <div *ngFor="let map of mapData">
            <div class="single-events-box mb-30 ">
                    <div class="events-box row">
                        <div class="col-lg">
                            <google-map  height="300px" width="auto" [center]="map.center">
                                <map-marker *ngFor="let marker of map.markers" [position]="marker.position"
                                    [label]="marker.label" [options]="marker.options"></map-marker>
                            </google-map>
                        </div>
                        <div class="events-content col-md">
                            <div class="content">
                                <h3>{{map.name}}</h3>
                                <span class="location"><i class='bx bx-map'></i>{{map.address}}</span>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<!-- <section class="blog-area bg-image ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let item of associations">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="" class="d-block">
                            <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12985.877160139242!2d7.904859!3d47.329626!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47902fd464061653%3A0xaacce4f0cbcd664f!2sGreen%20Mosque%20Aarburg%2C%20Turkish%20Islamic%20Association%20Aarburg!5e1!3m2!1str!2sus!4v1709627304326!5m2!1str!2sus"
                                        width="500" height="200" style="border:0;" allowfullscreen="" loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="" class="category">{{item.address}}</a>
                        <h3 class="playfair-display"><a routerLink="/single-blog">{{item.name}}</a></h3>
                    </div>
                </div>
            </div>
           

        </div>
    </div>
</section> -->