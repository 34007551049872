import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EventApplicationService } from 'src/app/services/eventApplication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  isLoading: boolean = false;
  eventForm: UntypedFormGroup;


  constructor(private fb: FormBuilder, private eventApplicationService: EventApplicationService) { }

  ngOnInit(): void {
    this.createForm();
  }



  createForm() {
    this.eventForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
    })

  }


  saveForm() {

    this.isLoading = true;
    if (this.eventForm.valid) {

      
      this.eventApplicationService.addEventApplication(this.eventForm.value).subscribe((data: any) => {
        
        this.isLoading = false;
        Swal.fire({
          icon: 'success',
          title: 'Başarılı',
          text: 'Başvurunuz başarıyla gönderildi!',
          confirmButtonText: 'Kapat',

        })
          .then((success) => {
            // this.router.navigateByUrl('/hac-bilgi/' + this.hajjDetail)
            window.location.reload();
          });

      }, (error: any) => {

        this.isLoading = false;
        {
          // console.error('servisten sonra hata', error);
          Swal.fire({
            icon: 'error',
            title: 'Hata',
            text: error.error[0],
            confirmButtonText: 'Kapat'

          }
          )

        }
      }

      )
    }
    else {
      this.isLoading = false;
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Başvurunuz sırasında bir hata oluştu',
        confirmButtonText: 'Kapat'
      }
      )
    }
  }

}

