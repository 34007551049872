<div *ngIf="isLoading" class="loader-wrapper">
        <div class="loader"></div>
        <div class="loader-section section-left"></div>
        <div class="loader-section section-right"></div>

</div>

<header class="header-area header-color-white">

        <header class="header-one sticky-header" [ngClass]="[windowScrolled ? 'sticky-on':'']">
                <div class="header-navigation" [ngClass]="[visible ? '' : 'breakpoint-on']"
                        (window:resize)="detectHeader()">
                        <div class="container-fluid d-flex align-items-center justify-content-between container-1470 ">
                                <span class="header-left">

                                        <a routerLink="/">
                                                <img src="assets/img/logo/itdvlogo.svg" alt="logo"
                                                        class="logo">
                                        </a>

                                </span>
                                <div class="header-right d-flex align-items-center justify-content-end">
                                        <div class="site-nav-menu" [ngClass]="navMethod ? 'menu-opened':''">
                                                <ul class="primary-menu" style="display: inline-block !important; margin-bottom: 1rem !important;
                                                margin-top:1rem !important;" *ngFor="let item of topmenus">
                                                        <li>
                                                                <a href="javascript:void(0)"
                                                                        [href]="item?.link ? item?.link : (item?.frontPageId ? item.slug+ '/'+ item.frontPageId : (item?.slug!='' ? item?.slug : '') )"
                                                                        target="{{ item?.link ? '_blank' : '' }}">
                                                                        {{ item.name }}
                                                                </a>


                                                                <span class="dd-trigger"
                                                                        *ngIf="item?.inverseParent?.length > 0"
                                                                        (click)="trigger(item)">
                                                                        <i class="fal"
                                                                                [ngClass]="item.open ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>
                                                                </span>

                                                                <ul class="submenu"
                                                                        *ngIf="item?.inverseParent?.length>0"
                                                                        [ngClass]="item.open ? 'd-block' : ''">

                                                                        <li *ngFor="let submenu of item?.inverseParent">
                                                                                <a href="javascript:void(0)"
                                                                                        [href]="submenu?.link ? submenu?.link : (submenu?.frontPageId ? submenu.slug+ '/'+ submenu.frontPageId : (submenu?.slug ? submenu?.slug : '') )"
                                                                                        target="{{ item?.link ? '_blank' : '' }}">
                                                                                        {{ submenu.name }}
                                                                                </a>

                                                                        </li>
                                                                </ul>


                                                        </li>


                                                </ul>
                                                <div class="others-option sidebar">
                                                        <div class="dropdown language-switcher d-inline-block"
                                                                [class.active]="classApplied2">
                                                                <button class="dropdown-toggle" type="button"
                                                                        (click)="toggleClass2()">
                                                                        <img *ngIf="language?.icon" [src]="photoUrl+language?.icon"
                                                                                class="shadow" alt="image">
                                                                              
                                                                        <span>{{ language?.name |translate }} <i
                                                                                        class='bx bx-chevron-down'></i></span>
                                                                </button>
                                                                <div class="dropdown-menu">
                                                                        <a href="#" *ngFor="let language of languages"
                                                                                (click)="setNewLanguageRefresh(language?.langcode)"
                                                                                class="dropdown-item d-flex align-items-center">
                                                                                <img [src]="photoUrl+language?.icon
                                                                        " class="shadow-sm" alt="flag">
                                                                                <span>{{ language?.name | translate
                                                                                        }}</span>
                                                                        </a>

                                                                </div>
                                                        </div>

                                                </div>
                                                <a href="javascript:void(0)" class="nav-close"
                                                        (click)="toggleNav()"></a>
                                        </div>
                                        <div class="header-extra d-flex align-items-center">
                                                <div class="offcanvas-widget d-lg-block d-none">

                                                </div>
                                                <div class="nav-toggler" [ngClass]="navMethod ? 'menu-opened':''"
                                                        (click)="toggleNav()">
                                                        <span></span><span></span><span></span>
                                                </div>
                                        </div>
                                        <div class="others-option navbar">
                                                <div class="dropdown language-switcher d-inline-block"
                                                        [class.active]="classApplied2">
                                                        <button class="dropdown-toggle" type="button"
                                                                (click)="toggleClass2()">
                                                                <img *ngIf="language?.icon" [src]="photoUrl+language?.icon" class="shadow"
                                                                        alt="image">
                                                                <span>{{ language?.name |translate}} <i
                                                                                class='bx bx-chevron-down'></i></span>
                                                        </button>
                                                        <div class="dropdown-menu">
                                                                <a href="#" *ngFor="let language of languages"
                                                                        (click)="setNewLanguageRefresh(language?.langcode)"
                                                                        class="dropdown-item d-flex align-items-center">
                                                                        <img [src]="photoUrl+language?.icon
                                                                " class="shadow-sm" alt="flag">
                                                               
                                                                        <span>{{ language?.name | translate }}</span>
                                                                </a>

                                                        </div>
                                                </div>

                                        </div>
                                </div>
                        </div>
                </div>

        </header>
</header>